import axios from 'axios';
import { GET_MARKETPLACE_SUGGESTIONS, LOADING, GET_MARKETPLACE_RESTAURANTS, CONVERTED_TO_ENTERPRIZE, MARKETPLACE_PAGE, GET_MARKETPLACE_COMBOS, MARKETPLACE_MOST_POPULAR_RESTAURANTS, GET_MARKETPLACE_FAVORITES, MARKETPLACE_NEW_RESTAURANTS_NEAR_YOU, MARKETPLACE_FILTERS, MARKETPLACE_SECTION_SCROLL_PERMISSION, GET_DYNAMICTITLES, MARKETPLACE_POPULAR_DISHES } from './types';
import { getError } from './errorActions';
import isEmpty from '../Utilities/is_empty';
import { AppThunkAction } from '../store';
import { showToastNotification } from '../Utilities/showToastNotification';
import { LocalStorageCompany } from '../Interfaces/PayloadsAndResponses/Auth';
import { setLoadingAction } from './loadingActions';
import dayjs from 'dayjs';
import { MarketplaceRestaurant } from '../Interfaces/PayloadsAndResponses/Marketplace';
import { arraySorting } from '../Utilities/ArraysUtilities';
// import { getAddressStringByLatLong } from './companyActions';
// import { getFutureDateWithTimeZone } from '../Utilities/getDateForMarketplace';
const queryString = require('query-string');

//Variables to get unique data
let marketPlaceMostPopularRestaurant : any = []
let publicMarketPlaceMostPopularRestaurant : any = []
let publicMarketPlaceNearYouRestautants:any = []
let marketPlaceNearYouRestautants:any = []
const noTimeZoneFound = 'America/Los_Angeles'

const sortingResponseForMarketplace = (arrayToBeSorted: MarketplaceRestaurant[]) => {
  const sortingType = JSON.parse(localStorage.getItem('sortingMarketPlace') || '{}')
  return arraySorting(arrayToBeSorted, sortingType)
}

export const getMarketplaceSuggestions = (keyword: string): AppThunkAction => dispatch => {
  axios
    .get(`searches?keyword=${keyword}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_MARKETPLACE_SUGGESTIONS,
        payload: res?.data
      })
    )
    .catch(err =>
      dispatch(getError(err))
    );
};
export const getRestaurants = (keyword: string, address_id: number, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, filterArrays: any, page: number,exclude_ids: number[], paginated?: boolean, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  if (delivery_at) {
    if (page === 1) {
      dispatch({
        type: GET_MARKETPLACE_RESTAURANTS,
        payload: [],
        loading: true,
        menuTypeloading: true
      }); // Dispatch the specific action for page 1
    } else {
      dispatch(setLoadingAction(true)); // Dispatch generic loading action for other pages
    }
    // store.dispatch({
    //   type: GET_PUBLIC_ADDRESSES,
    //   payload: address_id
    // })
    dispatch({
      type: MARKETPLACE_PAGE,
      payload: true
    })
    axios
      .get(`searches/restaurants?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&address_id=${address_id}&page=${page}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray, 'exclude_ids[]': exclude_ids },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        dispatch({
          type: GET_MARKETPLACE_RESTAURANTS,
          payload: res?.data,
          loading: false,
          paginated: paginated,
          menuTypeloading: false
        });
        if (page > 1) {
        dispatch(setLoadingAction(false))
        }
        marketPlaceMostPopularRestaurant = []
        marketPlaceNearYouRestautants = []
      })
      .catch(err =>
        dispatch(getError(err))
      );
  }
};

export const getPublicRestaurants = (keyword: string, lat: number | null, long: number | null,address_line: string, time_zone: string, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, zip_code: number | null, filterArrays: any, page: number, exclude_ids: number[], paginated?: boolean, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  
  const timeZone = time_zone ?? noTimeZoneFound
  if (delivery_at && !isEmpty(time_zone)) {
    if (page === 1) {
      dispatch({
        type: GET_MARKETPLACE_RESTAURANTS,
        payload: [],
        loading: true,
        menuTypeloading: true
      }); // Dispatch the specific action for page 1
    } else {
      dispatch(setLoadingAction(true)); // Dispatch generic loading action for other pages
    }
    axios
      .get(`searches/restaurants?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&lat=${lat}&long=${long}&address_line=${address_line}&page=${page}&time_zone=${timeZone}&zip_code=${zip_code}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray, 'exclude_ids[]': exclude_ids },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        dispatch({
          type: GET_MARKETPLACE_RESTAURANTS,
          payload: res?.data,
          loading: false,
          paginated: paginated,
          menuTypeloading: false
        });
        dispatch({
          type: MARKETPLACE_PAGE,
          payload: true
        })
        if (page > 1) {
          dispatch(setLoadingAction(false))
        }
        publicMarketPlaceMostPopularRestaurant = []
        publicMarketPlaceNearYouRestautants = []
      })
      .catch(err => {
        dispatch(getError(err));
      });
  }
};


export const getMarketplaceCombos = (keyword: string, address_id: number, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  if (delivery_at) {
    dispatch({
      type: GET_MARKETPLACE_COMBOS,
      payload: [],
      loading: true
    });
    dispatch({
      type: MARKETPLACE_PAGE,
      payload: true
    })

    axios
      .get(`searches/combos?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&address_id=${address_id}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        dispatch({
          type: GET_MARKETPLACE_COMBOS,
          payload: res?.data,
          loading: false
        });
      })
      .catch(err =>
        dispatch(getError(err))
      );
  }
};

export const getPublicMarketplaceCombos = (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, zip_code: number | null, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  if (isEmpty(zip_code) && !isEmpty(time_zone)) {
    dispatch({
      type: GET_MARKETPLACE_COMBOS,
      payload: [],
      loading: false
    });
    //we need zip code for combo generation if zip code is undefined we won't be able to generate combo on backend so we don't need to call this API
    return null
  } else {
    if (delivery_at) {
      dispatch({
        type: GET_MARKETPLACE_COMBOS,
        payload: [],
        loading: true
      });
      dispatch({
        type: MARKETPLACE_PAGE,
        payload: true
      })
  
      axios
        .get(`searches/combos?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&lat=${lat}&long=${long}&address_line=${address_line}&time_zone=${time_zone}&zip_code=${zip_code}`,
          {
            params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
            paramsSerializer: function (params) {
              return queryString.stringify(params, { indices: false })
            },
            headers: JSON.parse(localStorage.getItem('headers') || '{}')
          })
        .then(res => {
          dispatch({
            type: GET_MARKETPLACE_COMBOS,
            payload: res?.data,
            loading: false
          });
          dispatch({
            type: MARKETPLACE_PAGE,
            payload: true
          })
        })
        .catch(err => {
          dispatch(getError(err));
        });
    }
  }
};

export const getMarketplaceFavorites = (keyword: string, address_id: number, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  if (delivery_at) {
    dispatch({
      type: GET_MARKETPLACE_FAVORITES,
      payload: [],
      loading: true
    });
    dispatch({
      type: MARKETPLACE_PAGE,
      payload: true
    })

    axios
      .get(`searches/favorites?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&address_id=${address_id}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        const sortedResponse = sortingResponseForMarketplace(res?.data)
        dispatch({
          type: GET_MARKETPLACE_FAVORITES,
          payload: sortedResponse,
          loading: false
        });
      })
      .catch(err =>
        dispatch(getError(err))
      );
  }
};

// Status Loading
export const setLoading = () => {
  return {
    type: LOADING
  };
}

export const upgradeToEnterprize = (enterprizeLoaderHandler?: () => void): AppThunkAction => dispatch => {
  axios
    .put(`/companies/request_for_enterprise`, {},
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      if (res?.data?.success === true) {
        dispatch({
          type: CONVERTED_TO_ENTERPRIZE,
          payload: true
        })
        const company = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany
        const updatedCompanyData = { ...company, enterprise_status: 'requested' }
        localStorage.setItem('company', JSON.stringify(updatedCompanyData));
        enterprizeLoaderHandler && enterprizeLoaderHandler()
      } else {
        showToastNotification(res?.data?.message, 'Oops!');
      }
    })
    .catch(err => {
      dispatch(getError(err))
    });
};

export const addToFavouritesRestaurants = (restaurant_id: number): AppThunkAction => dispatch => {
  axios
    .post(`favorites?restaurant_id=${restaurant_id}`,{},
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .catch(err =>
      dispatch(getError(err))
    );
}

export const removeFavouritesRestaurants = (restaurant_id: number): AppThunkAction => dispatch => {
  axios
    .delete(`favorites?restaurant_id=${restaurant_id}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .catch(err =>
      dispatch(getError(err))
    );
}

export const getPublicMostPopularRestaurants = (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, zip_code: number | null, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  dispatch({
    type: MARKETPLACE_MOST_POPULAR_RESTAURANTS,
    payload: [],
    loading: true
  });
  const timeZone = time_zone ?? noTimeZoneFound

  if (delivery_at && !isEmpty(time_zone)) {
    axios
      .get(`searches/most_popular?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&lat=${lat}&long=${long}&address_line=${address_line}&time_zone=${timeZone}&zip_code=${zip_code}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        const sortedResponse = sortingResponseForMarketplace(res?.data)
        dispatch({
          type: MARKETPLACE_MOST_POPULAR_RESTAURANTS,
          payload: sortedResponse,
          loading: false
        });
        dispatch({
          type: MARKETPLACE_PAGE,
          payload: true
        })
        publicMarketPlaceMostPopularRestaurant = res?.data
        if (!isEmpty(res.data)) {
          dispatch({
            type: MARKETPLACE_SECTION_SCROLL_PERMISSION,
            payload: true
          })
        }
        dispatch(getPublicNewRestaurantsNearYou(keyword, lat, long, address_line, timeZone, menu_type, meal_type, delivery_at, zip_code, filterArrays, order_by, order_type))
      })
      .catch(err => {
                dispatch(getError(err));
      });
  }
};

export const getTrendingDishes = (keyword: string, address_id: number, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  dispatch({
    type: MARKETPLACE_POPULAR_DISHES,
    payload: [],
    loading: true
  });
  if (delivery_at) {
    axios
      .get(`searches/trending_dishes?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&address_id=${address_id}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {

        dispatch({
          type: MARKETPLACE_POPULAR_DISHES,
          payload: res?.data,
          loading : false,
          mostPopularFetched: true
        });
        dispatch({
          type: MARKETPLACE_PAGE,
          payload: true
        })
        marketPlaceMostPopularRestaurant = res?.data
        // dispatch(getNewRestaurantsNearYou(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, order_by, order_type))
      })
      .catch(err => {
        dispatch(getError(err));
      });
  }
}

export const getPublicTrendingDishes = (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, zip_code: number | null, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  dispatch({
    type: MARKETPLACE_POPULAR_DISHES,
    payload: [],
    loading: true
  });
  const timeZone = time_zone ?? noTimeZoneFound

  if (delivery_at && !isEmpty(time_zone)) {
    axios
      .get(`searches/trending_dishes?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&lat=${lat}&long=${long}&address_line=${address_line}&time_zone=${timeZone}&zip_code=${zip_code}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        dispatch({
          type: MARKETPLACE_POPULAR_DISHES,
          payload: res?.data,
          loading: false
        });
        dispatch({
          type: MARKETPLACE_PAGE,
          payload: true
        })
        publicMarketPlaceMostPopularRestaurant = res?.data
        if (!isEmpty(res.data)) {
          dispatch({
            type: MARKETPLACE_SECTION_SCROLL_PERMISSION,
            payload: true
          })
        }
        // dispatch(getPublicNewRestaurantsNearYou(keyword, lat, long, address_line, timeZone, menu_type, meal_type, delivery_at, zip_code, filterArrays, order_by, order_type))
      })
      .catch(err => {
                dispatch(getError(err));
      });
  }
};

export const getMostPopularRestaurants = (keyword: string, address_id: number, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  dispatch({
    type: MARKETPLACE_MOST_POPULAR_RESTAURANTS,
    payload: [],
    loading: true
  });
  if (delivery_at) {
    axios
      .get(`searches/most_popular?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&address_id=${address_id}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        const sortedResponse = sortingResponseForMarketplace(res?.data)

        dispatch({
          type: MARKETPLACE_MOST_POPULAR_RESTAURANTS,
          payload: sortedResponse,
          loading : false,
          mostPopularFetched: true
        });
        dispatch({
          type: MARKETPLACE_PAGE,
          payload: true
        })
        marketPlaceMostPopularRestaurant = res?.data
        dispatch(getNewRestaurantsNearYou(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, order_by, order_type))
      })
      .catch(err => {
        dispatch(getError(err));
      });
  }
}
export const getPublicNewRestaurantsNearYou = (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, zip_code: number | null, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  dispatch({
    type: MARKETPLACE_NEW_RESTAURANTS_NEAR_YOU,
    payload: [],
    loading: true
  });
  const timeZone = time_zone ?? noTimeZoneFound

  if (delivery_at && !isEmpty(time_zone)) {
    axios
      .get(`searches/new_restaurants_near_you?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&lat=${lat}&long=${long}&address_line=${address_line}&time_zone=${timeZone}&zip_code=${zip_code}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        const sortedResponse = sortingResponseForMarketplace(res?.data)
        dispatch({
          type: MARKETPLACE_NEW_RESTAURANTS_NEAR_YOU,
          payload: sortedResponse,
          loading: false
        });
        dispatch({
          type: MARKETPLACE_PAGE,
          payload: true
        })
        publicMarketPlaceNearYouRestautants = res?.data
        if (!isEmpty(res.data)) {
          dispatch({
            type: MARKETPLACE_SECTION_SCROLL_PERMISSION,
            payload: true
          })
        }
        const concateExcludeIds = [...publicMarketPlaceMostPopularRestaurant, ...publicMarketPlaceNearYouRestautants]?.map((item : any) => item.id) || [];
        const idsToBeExcludedFromPublicMarketplaceRestaurants = concateExcludeIds?.reduce((unique:any, item:any)=>
          unique?.includes(item) ? unique : [...unique, item]
        ,[]);
        dispatch(getPublicRestaurants(keyword, lat, long, address_line, timeZone, menu_type, meal_type, delivery_at, zip_code, filterArrays, 1, idsToBeExcludedFromPublicMarketplaceRestaurants, false, order_by, order_type))
      })
      .catch(err => {
        dispatch(getError(err));
      });
  }
};

export const getNewRestaurantsNearYou = (keyword: string, address_id: number, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  dispatch({
    type: MARKETPLACE_NEW_RESTAURANTS_NEAR_YOU,
    payload: [],
    loading: true
  });
  if (delivery_at) {
    axios
      .get(`searches/new_restaurants_near_you?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&address_id=${address_id}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        const sortedResponse = sortingResponseForMarketplace(res?.data)
        dispatch({
          type: MARKETPLACE_NEW_RESTAURANTS_NEAR_YOU,
          payload: sortedResponse,
          loading : false,
          newRestaurantsFetched: true
        });
        dispatch({
          type: MARKETPLACE_PAGE,
          payload: true
        })
        marketPlaceNearYouRestautants = res?.data
        const concateExcludeIds = [...marketPlaceMostPopularRestaurant, ...marketPlaceNearYouRestautants]?.map((item : any) => item.id) || [];
        const idsToBeExcludedFromMarketPlaceRestaurants = concateExcludeIds?.reduce((unique:any, item:any)=>
          unique?.includes(item) ? unique : [...unique, item]
        ,[]);
  
        dispatch(getRestaurants(keyword, address_id, menu_type, meal_type, delivery_at, filterArrays, 1, idsToBeExcludedFromMarketPlaceRestaurants, false, order_by, order_type))
      })
      .catch(err => {
        dispatch(getError(err));
      });
  }
}

export const getPublicMarketPlaceFilters = (keyword: string, lat: number | null, long: number | null, address_line: string, time_zone: string, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, zip_code: number | null, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  if (delivery_at && isEmpty(filterArrays) && !isEmpty(time_zone)) {
    dispatch({
      type: MARKETPLACE_FILTERS,
      payload: [],
      loading: true
    });
    axios
      .get(`searches/filters?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&lat=${lat}&long=${long}&address_line=${address_line}&time_zone=${time_zone}&zip_code=${zip_code}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {

        const queryStringPublic = window.location.search;
        // Create a URLSearchParams object
        const paramsDataDatapublic = new URLSearchParams(queryStringPublic);
        const publicURLCuisine = paramsDataDatapublic.getAll('filter-cuisine');
        const publicURLDietary = paramsDataDatapublic.getAll('filter-dietary')
        const publicURLIngredient = paramsDataDatapublic.getAll('filter-ingredient')


        let filterTypesArray = {
          "dietary": publicURLDietary,
          "ingredient": publicURLIngredient,
          "cuisine": publicURLCuisine
        };

        const addKeyToObject = (obj: any) => {
          obj.selected = true;
        }

        if (filterTypesArray?.dietary?.length >= 1) {
          res?.data?.forEach((obj: any) => {
            if (filterTypesArray?.dietary?.includes(obj?.name)) {
              addKeyToObject(obj);
            }
          });
        }

        if (filterTypesArray?.cuisine.length >= 1) {
          res.data?.forEach((obj: any) => {
            if (filterTypesArray?.cuisine?.includes(`${obj?.id}`)) {
              addKeyToObject(obj);
            }
          });
        }

        if (filterTypesArray?.ingredient.length >= 1) {
          res.data?.forEach((obj: any) => {
            if (filterTypesArray?.ingredient?.includes(obj?.name)) {
              addKeyToObject(obj);
            }
          });
        }

        dispatch({
          type: MARKETPLACE_FILTERS,
          payload: res.data,
          loading: false
        })
      })
      .catch(err => {
        dispatch(getError(err));
      });
  }
}

export const getMarketPlaceFilters = (keyword: string, address_id: number, menu_type = 'lunch', meal_type = 'buffet', delivery_at: string, filterArrays: any, order_by = 'rating', order_type = 'DESC'): AppThunkAction => dispatch => {
  if (delivery_at && (!isEmpty(keyword) || isEmpty(filterArrays))) {
    dispatch({
      type: MARKETPLACE_FILTERS,
      payload: [],
      loading: true
    });
    axios
      .get(`searches/filters?${isEmpty(keyword) ? '' : `keyword=${keyword}&`}menu_type=${menu_type}&meal_type=${meal_type}&delivery_at=${delivery_at}&address_id=${address_id}`,
        {
          params: { 'dietaries[]': filterArrays?.localDietArray, 'ingredients[]': filterArrays?.localIngredientsArray, 'cuisines[]': filterArrays?.localCuisinesArray },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        dispatch({
          type: MARKETPLACE_FILTERS,
          payload: res.data,
          loading: false
        })
      })
      .catch(err => {
        dispatch(getError(err));
      });
  }
}




// export const getMarketplaceIPAddressData = (date?: string): AppThunkAction => dispatch => {

//   let location = {
//     latitude: '',
//     longitude: '',
//   }
//   axios
//     .get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_KEY}`,
//       {
//         // headers: JSON.parse(localStorage.getItem('headers') || '{}')
//       }
//     )
//     .then(res => {

//       let address = res?.data
//       const {loc, postal } = address
//       const newLocationData = {
//         address_line: address?.city + ', ' + (address?.regionName ?? ''),
//         address_name: address?.city + ', ' + (address?.regionName ?? ''),
//         suite_no: '',
//         latitude: loc?.split(',')?.[0],
//         longitude: loc?.split(',')?.[1],
//         delivery_instructions: '',
//         street: '',
//         street_number: '',
//         city: address?.city,
//         state: address?.regionName,
//         zip: postal,
//         time_zone: address?.timezone,
//         formatted_address: address?.city + ', ' + (address?.regionName ?? ''),
//       };
//       location.latitude = loc?.split(',')?.[0]
//       location.longitude = loc?.split(',')?.[1]
//       localStorage.setItem("address", JSON.stringify(newLocationData))

//       // const dateForMarketplace = getFutureDateWithTimeZone(date, address?.timezone)
//       // dispatch(getPublicRestaurants('', address?.lat, address?.lon, address?.timezone, 'lunch', 'individual', dateForMarketplace, address?.zip, [], 20, 1))
//     })
//     .catch(err =>
//       dispatch(getError(err))
//     );

//     // const addressString = await getAddressStringByLatLong(+location.latitude, +location.longitude)

// };
const redirectToNewDeliverySeo = (dynamicTitle?: string | null) => {

  const currentHour = dayjs().hour();
  const dateForMarketplace = currentHour >= 14 ? dayjs().add(2, 'day') : dayjs().add(1, 'day');

  window.location.replace(`/menu/${dateForMarketplace.format('M-D-YYYY')}${!isEmpty(dynamicTitle) ? `?k=${dynamicTitle}` : ''}}`)
}

export const getDynamicTitles = (dynamicTitle: string | null, redirectToNewDelivery?: () => void): AppThunkAction<any> => dispatch => {
  axios
    .get(`/seo_headings${dynamicTitle ? `?k=${dynamicTitle}` : ''}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then((res) => {
      dispatch({
        type: GET_DYNAMICTITLES,
        payload: res.data
      })
      redirectToNewDelivery && redirectToNewDeliverySeo(dynamicTitle ?? '')
    })
    .catch((err) => {
      // dispatch(getError(err))
    }
    );
};

