// // Packages
// import React, { Component } from 'react';
// import dayjs from 'dayjs';
// import { connect } from 'react-redux';
// import { WithTranslation } from 'react-i18next';
// import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
// // React JSX Components
// import MenuLink from './MenuLink';
// import Backdrop from '../Backdrop';
// import InviteVendorPopUp from '../Popups/InviteVendorsPopup';
// // Utilities
// import isEmpty from '../../Utilities/is_empty';
// import { logoutUser, clearOldCart } from '../../Actions/authActions';
// // Types
// import { Menu, ReduxRootState, ServerCart } from '../../Interfaces/ReduxInterface/ReduxRootState';
// // CSS
// import './header.css';
// import { LocalStorageCompany, User, UserType } from '../../Interfaces/PayloadsAndResponses/Auth';
// import SignUpWidget from '../../Pages/Signup/SignUpWidget';
// import Login from '../../Pages/Login/Login';
// import { CartDetails, CartSummary } from '../../Interfaces/PayloadsAndResponses/Cart';
// import EnterprizeConfirmationPopUp from '../Popups/EnterprizeConfirmationPopUp';
// import EnterprizePlanViewerPopUp from '../Popups/EnterprizePlanViewerPopUp';
// import { Delivery } from '../../Interfaces/PayloadsAndResponses/Schedules';
// // import Login from '../../Pages/Login/Login';

// type Params = { date: string };

// type HeadersProps = {
//   i18n: null | WithTranslation;
//   menu: Menu;
//   converted_to_enterprize?: boolean;
//   invitedMeeting?: boolean;
//   fromPublicMarketPlace?: boolean;
//   parentComponentRef?: any;
//   toggleMenuDetailSideBar?: (() => void) | undefined;
//   scheduleMarketplaceDelivery?: boolean;
//   showLeftSidebarToggler?: boolean;
//   totalDeliveriesOnSingleDate?: undefined | number;
//   logoutUser: (history: RouteComponentProps['history']) => void;
//   clearOldCart: () => void;
//   cartDetails: CartDetails;
//   cartSummary: CartSummary;
//   serverCart: ServerCart;
//   cart_display_check?:boolean;
//   currentDelivery?: Delivery
// } & RouteComponentProps<Params>
// class Header extends Component<HeadersProps> {
//   localStorageCompany = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;
//   user = JSON.parse(localStorage.getItem('user') || '{}') as User;

//   state = {
//     isOpenLeftSideBar: false,
//     screenWidth: window.document.body.clientWidth,
//     enterprizeConfirm: false,
//     enterprizePopUp: false,
//     enterprizeEmailSent: false,
//     localStorageData: this.localStorageCompany?.enterprise_status === 'not_converted' ? true : false
//   }

//   updateScreenWidth = () => {
//     this.setState({ screenWidth: window.document.body.clientWidth })
//   }
//   componentDidMount() {
//     window?.addEventListener('resize', this.updateScreenWidth);
//   }

//   componentWillUnmount() {
//     window?.removeEventListener('resize', this.updateScreenWidth);
//   }

//   onLogoutClick = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
//     e?.preventDefault();
//     this.props.logoutUser(this.props?.history);
//     return false;
//   }
//   showDeliveryDetailsHandler = () => {
//     if(this.props?.currentDelivery?.status === "approved") {
//       this.props?.parentComponentRef.setState({ showDeliveryOrdersSidebar: true, isOpenSidebar: false })
//     } else {
//       this.props.parentComponentRef.setState({ showCart: true })
//     }
//   }
//   closeModal = () => {
//     this.setState({ enterprizePopUp : false})
//   }
//   closeEnterpriseConfirmationPopUp = () => {
//     this.setState({ enterprizePopUp : false, enterprizeConfirm : false})
//   }
//   openConfirmEnterprizePopUp = () => {
//     this.setState({ enterprizePopUp : false , enterprizeConfirm : true, enterprizeEmailSent: true})
//   }
//   openEnterprizeModal = () => {
//     this.setState({ enterprizePopUp : true})
//   }

//   scheduleMarketplace = () => {
//     const {time_zone} = this.user
//     let document: Document = window.document;
//     const marketplace = JSON.parse(localStorage.getItem('company') || '{}')?.enable_marketplace;
//     // const headers = JSON.parse(localStorage.getItem('headers') || '{}')
//     const pathName = this.props.history.location.pathname.split('/');
//     let dateParam = this.props.match.params.date || dayjs().format('M-D-YYYY'); // If not on Menu Page then Set to Today Date.

//     let zonedDateTime = new Date(new Date().toLocaleString("en-US", { timeZone: time_zone }))
//     let thresholdDateTime = new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00')

//     let dateForMarketplace = dayjs(dateParam, 'M-D-YYYY') <= dayjs() ?
//       // date for marketplace with threshold: 2pm
//       dayjs().add((dayjs(zonedDateTime).isAfter(new Date(thresholdDateTime))? 2 : 1), 'day').format('M-D-YYYY')  
//       : dateParam

//     // if (this.props.fromPublicMarketPlace && !isEmpty(headers)) {
//     //   // this.props.history.push({pathname: `//date`})
//     //   this.props?.parentComponentRef?.setState({showCart: true})
//     // }
//     // clearing cartData if it is checkedout
//     if (this.props?.serverCart?.cart_at_login?.status !== 'pending') {
//       this.props.clearOldCart()
//     }
    
//     if (pathName[1] === 'menu') { // On Menu Detail Page.
//       if (marketplace && document.getElementById('scheduleClick')) {
//         document.getElementById('scheduleClick')?.click();
//         this.props.history.push({ pathname: `/menu/${dateForMarketplace}/` });
//       } else if (this.props.fromPublicMarketPlace) {
//         this.props.history.push({ pathname: `/home/${dateForMarketplace}/`, state: { date: dateForMarketplace } });
//       } else {
//         this.props.history.push({ pathname: `/dashboard`, state: { date: dateForMarketplace } });
//       }
//     } else if (pathName[1] === 'dashboard') {// On Dashboard Detail Page.
//       if (marketplace) {
//         this.props.history.push({ pathname: `/menu/${dateForMarketplace}/` });
//       } else {
//         document.getElementById('newDeliveryWithoutMarketplace')?.click?.();
//       }
//     } else { // Neither on Menu Detail Page Nor On Dashboard Page.
//       if (marketplace) {
//         this.props.history.push({ pathname: `/menu/${dateForMarketplace}/` });
//       } else {
//         this.props.history.push({ pathname: `/dashboard`, state: { date: dateForMarketplace } });
//       }
//     }
//   }

//   inviteVendor = React.createRef<() => void>().current;
//   marketPlaceSignUp = React.createRef<() => void>().current;
//   marketPlaceLogin = React.createRef<() => void>().current;

//   // Redirecting function for login And SignUp popUps
//   signupLoginRedirect = (page: string) => {
//     if (page === 'login') {
//       this.setState({ isOpenLeftSideBar: false }); this.marketPlaceSignUp?.();
//     } else if (page === 'signup') {
//       this.setState({ isOpenLeftSideBar: false }); this.marketPlaceLogin?.();
//     }
//   }
//   render() {
//     const pathCheckForCart = this.props.history.location.pathname.split('/');
//     const { screenWidth, isOpenLeftSideBar } = this.state;

//     const {
//       i18n = null,
//       menu: { menu_tab_meeting = null } = {},
//       invitedMeeting = false,
//       toggleMenuDetailSideBar = undefined,
//       scheduleMarketplaceDelivery = false,
//       showLeftSidebarToggler = false,
//       totalDeliveriesOnSingleDate = undefined,
//       history: { location: { search = null, pathname = '' } = {} } = {}
//     } = this.props || {};

//     const searchParams = search;
//     const user = JSON.parse(localStorage.getItem('user') || '{}') as User;
//     const company = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;
//     let isAdmin = user && user.user_type === UserType.ADMIN; // userType
//     const logoRedirectUrl = isEmpty(menu_tab_meeting) ? `/dashboard` : `/menu/${dayjs(menu_tab_meeting?.delivery_at)?.format('M-D-YYYY')}/${menu_tab_meeting?.slug}`
//     const pathName = pathname.split('/')[1];

//     const navbarLinksList = [
//       ...(isAdmin ? [{
//         label: 'New Delivery',
//         onClickHandler: this.scheduleMarketplace,
//         isNavLink: false,
//         icon: <i className="fas fa-store-alt"></i>,
//         iconClass: 'fas fa-store-alt',
//         className: `btn btn-sm btn-custom-primary new-delivery ml-3 py-2 px-3 ${scheduleMarketplaceDelivery ? 'active' : ''}`
//       }] : []),
//       ...(menu_tab_meeting ? [{
//         label: 'Your Orders',
//         isNavLink: true,
//         iconClass: 'fas fa-hamburger',
//         onClickHandler: () => { },
//         path: `/menu/${dayjs(menu_tab_meeting?.delivery_at)?.format('M-D-YYYY')}/${menu_tab_meeting?.slug}`,
//         className: `menu-detail-page mr-4 ml-3 ${['menu'].includes(pathName) && !scheduleMarketplaceDelivery ? 'active' : ''}`
//       }] : []),
//       // ...(!this.props?.fromPublicMarketPlace ? [
//       // {
//       //   label: 'Order History',
//       //   isNavLink: true,
//       //   iconClass: 'fas fa-calendar-alt',
//       //   path: `/order-history`,
//       //   className: `order-history mr-4 ${(!menu_tab_meeting) ? 'ml-3' : ''} ${pathName === 'orders' || pathName === 'order-history' ? 'active' : ''}`,
//       // }] : []),
//       ...(isAdmin ? [
//         {
//           label: 'Reports',
//           isNavLink: true,
//           iconClass: 'fas fa-chart-pie',
//           path: `/reports`,
//           className: `reports mr-4 ${pathName === 'reports' ? 'active' : ''}`,
//           newBadge: false
//         }] : []),
//     ]

//     const adminLinks = (
//       <>
//         {company?.self_signup === false && <MenuLink
//           classNames='account'
//           icon='fas fa-building'
//           path='/account'
//           label='Account'
//         />}
//         {company?.self_signup === false && (user?.allow_admin_to_manage_users ? <MenuLink
//           classNames='users'
//           icon='fas fa-users'
//           path='/users'
//           label='Users'
//         /> : '')}
//         <MenuLink
//           classNames='invite-vendors'
//           icon='fas fa-store-alt'
//           label='Invite Vendors'
//           onClick={() => { this.setState({ isOpenLeftSideBar: false }); this.inviteVendor?.(); }}
//         />
//         {/* <MenuLink
//           classNames='signup'
//           icon='fas fa-store-alt'
//           label='Invite Vendors'
//           onClick={() => { this.setState({ isOpenLeftSideBar: false }); this.marketPlaceSignUp?.(); }}
//         /> */}
//       </>
//     );
//     const userLinks = (
//       <>
//         <MenuLink
//           classNames='profile'
//           icon='fas fa-user'
//           path='/profile'
//           label='Profile'
//         />
//       </>
//     );
//     const bottomLinks = (
//       <>
//         <MenuLink
//           classNames='logout'
//           icon='fas fa-sign-out-alt'
//           path='/signin'
//           label='Logout'
//           onClick={this.onLogoutClick.bind(this)}
//         />
//       </>
//     );

//     const userDropdownLinks = <>
//       <div className='dropdown-item profile-dropdown-header-item py-3 px-5 text-center'>
//         <img
//           aria-hidden
//           alt='Company Logo'
//           className='user-image m-3 '
//           src={(company && company.image && company.image.url) ? company.image.url : '/imgs/userprofile.jpg'}
//         />
//         <br />
//         <strong className='text-capitalize'>
//           {user.first_name ? user.first_name : user.name} {user.last_name ? user.last_name : user.name}
//         </strong>
//         <br />
//         <small className='muted'>{user.email}</small>
//       </div>
//       <div className='dropdown-divider' />
//       <div
//         onClick={e => { e.stopPropagation(); this.setState({ isOpenLeftSideBar: false }) }}
//       >
//         {/* This Link is added here manually to be at the top of list */}
//         <MenuLink
//           classNames='dashboard'
//           icon='fas fa-tachometer-alt'
//           path={`/dashboard${searchParams || ''}`}
//           label='Dashboard'
//         />
//         <MenuLink
//         classNames='orderHistory'
//         icon='fas fa-calendar-alt'
//         path={`/order-history`}
//         label='Order History'
//         // classNames=`${pathName === 'orders' || pathName === 'order-history' ? 'active' : ''}`,
//         />
//         {screenWidth <= 992 && navbarLinksList?.map((navLink, index) => <React.Fragment key={navLink?.label}>
//           {(isAdmin && index === 0 && toggleMenuDetailSideBar) ? '' :
//             <MenuLink
//               classNames={''}
//               icon={navLink?.iconClass}
//               path={navLink?.path || null}
//               label={navLink?.label}
//               onClick={navLink?.onClickHandler}
//             />}
//         </React.Fragment>)
//         }
//         {userLinks}
//         {isAdmin ? adminLinks : ''}
//         {bottomLinks}
//         <div className='dropdown-divider' />
//         <div className='p-2 px-3'>
//           <a className='dropdown-item profiledropdownitem text-secondary d-inline p-0 privacy-policy'
//             href='https://chowmill.com/privacy-policy/'
//             target='_blank' rel='noopener noreferrer'
//             style={{ 'textDecoration': 'underline' }}>
//             Privacy
//           </a>
//           <a className='dropdown-item profiledropdownitem text-secondary d-inline p-0 ml-3 terms-of-service'
//             href='https://chowmill.com/terms-of-service/'
//             target='_blank'
//             rel='noopener noreferrer'
//             style={{ 'textDecoration': 'underline' }}>
//             Terms
//           </a>
//         </div>
//       </div>
//     </>

//     return (
//       <React.Fragment>
//         <nav className={`navbar px-3 navbar-light bg-white border-bottom fixed-top py-3 ${(Object.keys(user).length === 0)&& "non-loginuser-header py-3"}`} style={{ zIndex: 5 }}>
//           <div className="container px-0">
//           {(this.props.fromPublicMarketPlace) || ((showLeftSidebarToggler) && screenWidth <= 992 &&
//             <button className='btn d-flex align-items-center' onClick={toggleMenuDetailSideBar}>
//               <i className='fas fa-bars' style={{ fontSize: 25 }} />
//               {!invitedMeeting && !scheduleMarketplaceDelivery && <span className='all-deliveries-count ml-2' style={{ width: 25, height: 25 }}>{totalDeliveriesOnSingleDate || 0}</span>}
//             </button>)
//           }
//           <div className={menu_tab_meeting === undefined ? 'pointer-none' : ''}>
//             <Link className={'navbar-brand'} to={logoRedirectUrl}>
//               <img alt='Chowmill' src='/imgs/chowmill_icon.svg' className='logo-chowmill' /> <span className='logo-chowmill-t'>Chowmill</span>
//             </Link>
//           </div>

//           {invitedMeeting !== true && user && screenWidth > 992 ?
//             <div className='flex-grow-1' id='chowmillNavbarPrim'>
//               <ul className='d-flex'>
//                 {navbarLinksList?.map(({ isNavLink, icon, className, path, label, onClickHandler, newBadge }) => <React.Fragment key={label + `${path}`}>
//                   {(isNavLink && path) ?
//                     <Link className={`nav-link prim-link p-2 ${className}`} to={path}>
//                       {i18n && i18n.t(label)}&nbsp;
//                       {newBadge && <span className='badge badge-danger'>  New</span>}
//                     </Link> :
//                     <button className={`nav-link prim-link p-2 ${className}`}
//                       onClick={() => { onClickHandler?.() }}
//                       style={{ borderRadius: 1000 }}
//                     >
//                       {icon || ''} &nbsp; {i18n && i18n.t(label)}</button>}
//                 </React.Fragment>)
//                 }
//               </ul>
//             </div>
//             : ''}
//             {/* // SignUP Login for Public Market Place */}
//             <div className="col-lg-4 d-none d-lg-block px-0">
//             <div className="has-search">
//               <span className="fa fa-search form-control-feedback"></span>
//               <input type="search" className="form-control rounded-pill position-relative bg-gray-900 border-0" placeholder="Search Food, Restaurants, Cuisines"/>
//               <span className="badge badge-pill custome-gray-badge px-3 py-2 py-md-1 m-1 position-absolute pills-position">food <i className="ml-2 fas fa-times cursor-pointer" aria-hidden="true"></i></span>
//             </div>
//             </div>
//              {/* Enterprise nav icons */}
//              <div className="d-flex align-items-center mx-3">
//               <button className='btn upgrade-btn rounded-pill'>
//                 <img src="/imgs/crown.svg" alt="crown" className='pr-2' />Upgrade
//               </button>
//              </div>
//               {/* <div className="align-items-center d-none d-md-inline-flex mx-3 btn upgrade-btn rounded-pill">
//               {screenWidth > 992  &&<>
//                 {company?.enterprise_status !== 'requested' ? <span><img src="/imgs/crown.svg" alt="crown" /></span>
//                   :
//                   <span style={{ 'opacity': '30%' }}><img src="/imgs/crown.svg" alt="crown"/></span>
//                 }
//               </>
//               }
//               {company && company?.enterprise_status !== 'converted' && company?.self_signup === true && screenWidth > 992  && <button className='nav-link bg-transparent border-0 p-2 pl-0 menu-detail-page d-flex align-items-center cursor-pointer fs-18 fw-600' style={{ color: '#007bff' }} title='Request already sent'>
//                 {company?.enterprise_status === 'not_converted' ? <span onClick={this.openEnterprizeModal}>Upgrade</span>
//                   :
//                   <>
//                     {(!this.props?.converted_to_enterprize || company?.enterprise_status === 'requested') && <span style={{ 'opacity': '30%' }}>Upgrade</span>}
//                   </>
//                 }
//               </button>
//               }
//             </div> */}
//           {screenWidth >= 440 ?
//             <React.Fragment>
//               {this.props.fromPublicMarketPlace && <div className='text-nowrap profilelink position-relative cursor-pointer ml-auto'
//                 onClick={() => { this.setState({ isOpenLeftSideBar: false }); this.marketPlaceLogin?.(); }}
//               // onClick={() => { this.setState({ isOpenLeftSideBar: true }) }}
//               >
//                 {/* <div id='autoThumbnailParent'>
//                   <div id='autoThumbnailChild'> */}
//                 <button className='btn login-btn rounded-pill'>
//                   {/* <span className='hidden-xs text-capitalize' id='autoHiddenDrop'>Login</span> */}
//                   <img src="/imgs/SVGs/user-login-icon.svg" id='autoHiddenDrop' className='user-login-icon' alt="customer-login"></img>
//                 </button>
//                 {/* </div>
//                 </div> */}
//               </div>}
//               {this.props.fromPublicMarketPlace && <div className='text-nowrap profilelink position-relative cursor-pointer pl-2'
//                 onClick={() => { this.setState({ isOpenLeftSideBar: false }); this.marketPlaceSignUp?.(); }}
//               // onClick={() => { this.setState({ isOpenLeftSideBar: true }) }}
//               >
//                 {/* <div id='autoThumbnailParent'>
//                   <div id='autoThumbnailChild'> */}
//                 {/* <button className="btn btn-danger btn-custom-danger rounded-pill">
//                   <span className='hidden-xs text-capitalize' id='autoHiddenDrop'>Sign up </span>
//                 </button> */}
//                 {/* </div>
//                 </div> */}
//               </div>}
//             </React.Fragment> :
//             <React.Fragment>
//               {this.props.fromPublicMarketPlace && <div className='cursor-pointer ml-auto mr-2' onClick={() => { this.setState({ isOpenLeftSideBar: false }); this.marketPlaceLogin?.(); }}>
//                 <img src='/imgs/SVGs/nonlogged-in.svg' alt="customer-login"/>
//               </div>}
//             </React.Fragment>
//           }
//             {/* Cart icon for non-logged in users */}
//           {((this.props.fromPublicMarketPlace) || (company?.self_signup === true)) && screenWidth < 3600 &&
//             <div className='d-flex delivery-details-cart shopping-cart cursor-pointer' onClick={() => this.props.parentComponentRef.setState({ showCart: true })}>
//               {this.props.cartDetails?.itemCount > 0 && <span className="mobile-basket-icons-wrapper position-relative ml-2">
//                 <i className="fas fa-shopping-cart"></i>
//                 <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position-nonglogin border border-white'>{this.props.cartDetails?.itemCount ?? 0}</span>
//               </span>
//               }
//             </div>}
//             {/* // cart */}

//             {/* {// Removed the CART icon from header Temporarily //} */}
//             {((this.props.fromPublicMarketPlace) || (company?.self_signup === true)) && false && !this.props?.parentComponentRef?.state.hideHeaderCart &&
//               <div className='d-flex delivery-details-cart shopping-cart'>
//                 <span className="icon-wrapper-large position-relative shopping-bag ml-2">
//                   <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="33.003" viewBox="0 0 29.002 33.003">
//                     <path id="bag_new" data-name="bag new" d="M7856,13129a6.508,6.508,0,0,1-6.5-6.5v-15a3.506,3.506,0,0,1,3.5-3.5h4.5v-1.5a6.5,6.5,0,0,1,13,0v1.5h4.5a3.507,3.507,0,0,1,3.5,3.5v15a6.508,6.508,0,0,1-6.5,6.5Zm-4.5-21.5v15a4.508,4.508,0,0,0,4.5,4.5h16a4.507,4.507,0,0,0,4.5-4.5v-15a1.505,1.505,0,0,0-1.5-1.5h-4.5v5a1,1,0,0,1-2,0v-5h-9v5a1,1,0,0,1-2,0v-5H7853A1.5,1.5,0,0,0,7851.5,13107.5Zm8-5v1.5h9v-1.5a4.5,4.5,0,0,0-9,0Z" transform="translate(-7849.5 -13095.998)" fill="#dc3644"/>
//                   </svg>
//                   <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position'>{this.props.cartDetails?.itemCount ?? 0}</span>
//                 </span>
//                 {/* <div className='pl-3'>
//                   <p className='fs-18 fw-normal text-gray-600 m-0'>{`$${this.props.cartSummary.total ?? '0'}`}</p>
//                   {this.props?.cartDetails?.itemCount ?
//                     <span className="fs-18 fw-normal text-decoration-none" onClick={() => this.props.parentComponentRef.setState({ showCart: true })}>
//                       View Cart
//                     </span>
//                   :
//                     <span className="fs-18 fw-normal text-decoration-none">
//                       Cart Empty
//                     </span>
//                   }
//                 </div> */}
//               </div>
//             }
//           {/* Enterprise nav icons */}
//           {/* <div className="align-items-center d-none d-md-inline-flex">
//           {(isAdmin || (this.user?.ss_user === true)) && screenWidth > 992 &&<>
//             {company?.enterprise_status !== 'requested' ? <span><img src="/imgs/crown.svg" alt="crown" /></span>
//               :
//               <span style={{ 'opacity': '30%' }}><img src="/imgs/crown.svg" alt="crown"/></span>
//             }
//           </>
//           }
//           {company && company?.enterprise_status !== 'converted' && company?.self_signup === true && screenWidth > 992  && <button className='nav-link bg-transparent border-0 p-2 pl-0 menu-detail-page d-flex align-items-center cursor-pointer fs-18 fw-600' style={{ color: '#007bff' }} title='Request already sent'>
//             {company?.enterprise_status === 'not_converted' ? <span onClick={this.openEnterprizeModal}> Upgrade to Enterprise Plan</span>
//               :
//               <>
//                 {(!this.props?.converted_to_enterprize || company?.enterprise_status === 'requested') && <span style={{ 'opacity': '30%' }}> Upgrade to Enterprise Plan</span>}
//               </>
//             }
//           </button>
//           }
//           </div> */}
//           {!this.props.fromPublicMarketPlace && !invitedMeeting &&
//             <div className='nav-link text-nowrap profilelink position-relative cursor-pointer d-flex py-0'
//             >
//               {(this.props.cart_display_check || pathCheckForCart[3])&& this.props.serverCart.cart_at_login.orders_quantity > 0  && <span className='mobile-basket-icons-wrapper d-lg-none mr-2 mt-1' onClick={() => {this.showDeliveryDetailsHandler()}}>
//                 <i className="fas fa-shopping-cart text-white"></i>
//               </span>}
//               <div id='autoThumbnailParent'>
//                 {company?.self_signup === true ? (company?.enterprise_status === 'converted' && screenWidth < 992 && <span className='rounded-circle position-absolute icon-wrapper-badge-position'><img className='mobile-crown' src="/imgs/crown.svg" alt="crown" /></span>) : (isAdmin && screenWidth < 992 && <span className='rounded-circle position-absolute icon-wrapper-badge-position'><img className='mobile-crown' src="/imgs/crown.svg" alt="crown" /></span>)}
//                 <div id='autoThumbnailChild' onClick={() => { this.setState({ isOpenLeftSideBar: true }) }}>
//                   <span className='hidden-xs text-capitalize' id='autoHiddenDrop'>{user?.first_name?.charAt(0)} </span>
//                 </div>
//               </div>
//               {isOpenLeftSideBar && <Backdrop onClick={() => this.setState({ isOpenLeftSideBar: false })} />}
//               {screenWidth > 992 ?
//                 <>
//                   {isOpenLeftSideBar &&
//                     <div className='dropdown-menu-right profile-dropdown bg-white position-absolute' style={{ borderRadius: '17px' }} id='profile' aria-labelledby='navbarDropdown'>
//                       {userDropdownLinks}
//                     </div>}
//                 </> :
//                 <div className='right-sidebar' style={{ transform: isOpenLeftSideBar ? 'translateX(0)' : 'translateX(100%)' }}>
//                   {userDropdownLinks}
//                 </div>
//               }
//             </div>}
//             </div>
//         </nav>
//         {/* <div className='container-fluid'>
//           <div className='row'>
//             <div className='col-md-2 ml-md-auto'>
//               <div className='dropdown-menu'>
//                 <form >
//                   <div className='form-group'>
//                     <label htmlFor='exampleDropdownFormEmail1'>Email address</label>
//                     <input type='email' className='form-control' id='exampleDropdownFormEmail1' placeholder='email@example.com' />
//                   </div>
//                   <div className='form-group'>
//                     <label htmlFor='exampleDropdownFormPassword1'>Password</label>
//                     <input type='password' className='form-control' id='exampleDropdownFormPassword1' placeholder='Password' autoComplete='false' />
//                   </div>
//                   <div className='form-check'>
//                     <input type='checkbox' className='form-check-input' id='dropdownCheck' />
//                     <label className='form-check-label' htmlFor='dropdownCheck'>
//                       Remember me
//                     </label>
//                   </div>
//                   <button
//                     type='submit'
//                     className='btn btn-primary'
//                   >
//                     Sign in
//                   </button>
//                 </form>
//                 <div className='dropdown-divider'></div>
//                 <Link className='dropdown-item' to='/'>New around here? Sign up</Link>
//                 <Link className='dropdown-item' to='/'>Forgot password?</Link>
//               </div>
//             </div>
//           </div>
//         </div> */}
//         <InviteVendorPopUp
//           onRef={(openModalRef: (() => void) | null) => { this.inviteVendor = openModalRef }}
//         />
//         {this.props.fromPublicMarketPlace &&
//         <>
//           <SignUpWidget
//             isMarketPlaceSignUp
//             signupLoginRedirect={this.signupLoginRedirect}
//             // isOpenSignup
//             onRef={(openModalRef: (() => void) | null) => { this.marketPlaceSignUp = openModalRef }}
//             // closeSignup={() => { this.setState({ showSignupPopup: false }); document.title = 'Menu | Chowmill'; }}
//           />
//           <Login
//             {...this.props}
//             isMarketPlaceLogin
//             signupLoginRedirect={this.signupLoginRedirect}
//             onRef={(openModalRef: (() => void) | null) => { this.marketPlaceLogin = openModalRef }}
//           />
//         </>
//         }
//         {this.state.enterprizePopUp && <EnterprizePlanViewerPopUp
//         modalIsOpen={this.state.enterprizePopUp}
//         closeModal={this.closeModal}
//         confirmAction={this.openConfirmEnterprizePopUp}
//         />}
//         {this.state.enterprizeConfirm && <EnterprizeConfirmationPopUp
//           modalIsOpen={this.state.enterprizeConfirm}
//           closeModal={this.closeEnterpriseConfirmationPopUp}
//           confirmAction={() => { }}
//         />
//         }
//       </React.Fragment>
//     )
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   state_history: state,
//   menu: state?.menu,
//   auth: state?.auth,
//   company_deliveries_for_orders: state?.order.company_deliveries_for_orders,
//   i18n: state?.i18n.i18n,
//   cartDetails: state?.cart.details,
//   cartSummary: state?.cart.summary,
//   converted_to_enterprize: state?.company_data_reducer?.converted_to_enterprize,
//   cart_display_check: state.server_cart.cart_display_check,
//   serverCart:state.server_cart,
// });

// export default connect(mapStateToProps, { logoutUser, clearOldCart })(withRouter(Header));

// Packages
import React, { FC, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import queryString from "query-string";
// React JSX Components
import MenuLink from './MenuLink';
import Backdrop from '../Backdrop';
import InviteVendorPopUp from '../Popups/InviteVendorsPopup';
// Utilities
import isEmpty from '../../Utilities/is_empty';
import { logoutUser, clearOldCart } from '../../Actions/authActions';
// Types 
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// CSS 
import './header.css';
import { LocalStorageCompany, User, UserType } from '../../Interfaces/PayloadsAndResponses/Auth';
import SignUpWidget from '../../Pages/Signup/SignUpWidget';
import Login from '../../Pages/Login/Login';
import EnterprizeConfirmationPopUp from '../Popups/EnterprizeConfirmationPopUp';
import EnterprizePlanViewerPopUp from '../Popups/EnterprizePlanViewerPopUp';
import { Delivery } from '../../Interfaces/PayloadsAndResponses/Schedules';
import { MarketplaceRef } from '../../Pages/Menu/MarketplacePreview/Marketplace';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
import ReactTooltip from 'react-tooltip';
import { getDateForMarketplace } from '../../Utilities/getDateForMarketplace';
import MeetingDetailsPopUp, { MeetingDetailsPopUpRef } from '../Popups/MeetingDetailsPopUp';
import { AdminDeliveryRequest } from '../../Interfaces/PayloadsAndResponses/Menu';
import { Range } from 'react-date-range';
import { addDays } from 'date-fns';
import queryString from 'query-string';
import { getMeetingsByRange } from '../../Actions/menuActions';
import { MARKETPLACE_PAGE } from '../../Actions/types';
const queryFormat = { arrayFormat: 'separator' as 'separator', arrayFormatSeparator: '|' };
// import Login from '../../Pages/Login/Login';

// type Params = { date: string };
interface HeadersProps {
  invitedMeeting?: boolean;
  fromPublicMarketPlace?: boolean;
  fromPublicRestaurantMenu?: boolean;
  parentComponentRef?: any;
  toggleMenuDetailSideBar?: (() => void) | undefined;
  scheduleMarketplaceDelivery?: boolean;
  showLeftSidebarToggler?: boolean;
  totalDeliveriesOnSingleDate?: undefined | number;
  currentDelivery?: Delivery;
  showCart?: (isOpen: boolean) => void;
  showDeliveryOrdersSidebar?: (isOpen: boolean) => void;
  isOpenSidebar?: (isOpen: boolean) => void;
  hideHeaderCart?: boolean;
}

interface ModalState {
  type: 'login' | 'signup' | 'inviteVendor' | null;
  isOpen: boolean;
}

const Header: FC<HeadersProps> = ({
  currentDelivery,
  parentComponentRef,
  fromPublicMarketPlace,
  scheduleMarketplaceDelivery,
  toggleMenuDetailSideBar,
  showLeftSidebarToggler,
  invitedMeeting,
  totalDeliveriesOnSingleDate,
  showCart,
  showDeliveryOrdersSidebar,
  isOpenSidebar,
  hideHeaderCart
}) => {  
  const urlAddressHandler = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params.has('address')) {
      params.delete('address');
    }
    if (params.has('placeId')) {
      params.delete('placeId');
    }
    url.search = params.toString();
    url.pathname = '/';
  
    return url.toString();
  };

  // const localStorageCompany = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;
  const user = JSON.parse(localStorage.getItem('user') || '{}') as User;

  const {i18n} = useSelector((state: ReduxRootState) => state.i18n);
  const {cart_at_login, cart_display_check} =  useSelector((state: ReduxRootState) => state.server_cart);
  const menu = useSelector((state: ReduxRootState) => state.menu);
  const {details: cartDetails } = useSelector((state: ReduxRootState) => state.cart);
  // const cartDataRedux = useSelector((state: ReduxRootState) => state.cart);
  const CartItemsCount = useSelector((state: ReduxRootState) => state.cart.items);

  const cartSummaryItemsCount = CartItemsCount?.reduce(
    (total, item) => total + item?.quantity,
    0
  );

  const converted_to_enterprize = useSelector((state: ReduxRootState) => state?.company_data_reducer?.converted_to_enterprize);
  const show_searchbar = useSelector((state: ReduxRootState) => state?.marketplaceSuggestions?.showSearchBarOnMarketPlace);
  const dispatch: any = useDispatch();
  const history: any = useHistory();
  const {date} = useParams<{ date: string | undefined }>();
  const [isOpenLeftSideBar, setIsOpenLeftSideBar] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<any>(window.document.body.clientWidth);
  const [enterprizeConfirm, setEnterprizeConfirm] = useState<boolean>(false);
  const [enterprizePopUp, setEnterprizePopUp] = useState<boolean>(false);
  const { auth } = useSelector((state: ReduxRootState) => state);
  // const [enterprizeEmailSent, setEnterprizeEmailSent] = useState<boolean>(false);
  // const [localStorageData, setLocalStorageData] = useState<boolean>(localStorageCompany?.enterprise_status === 'not_converted' ? true: false)
  const [modalState, setModalState] = useState<ModalState>({type: null , isOpen: false});

  const pathCheckForCart = history.location.pathname.split('/');
  // const searchParams = queryString.parse(window.location.search);
  const company = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;
  let isAdmin = user && user.user_type === UserType.ADMIN; // userType
  // const logoRedirectUrl = isEmpty(menu?.menu_tab_meeting) ? `/dashboard` : `/menu/${dayjs(menu?.menu_tab_meeting?.delivery_at)?.format('M-D-YYYY')}/${menu?.menu_tab_meeting?.slug} `;
  // Redirect to '/menu' when menu_tab_meeting is not empty
  const logoRedirectUrl = auth.isAuthenticated
  ? `/menu/${dayjs(menu?.menu_tab_meeting?.delivery_at)?.format('M-D-YYYY')}/${menu?.menu_tab_meeting?.slug}`
  : urlAddressHandler();
  const pathName = history.location.pathname.split('/')[1];
  const { marketplace } = parentComponentRef || {};
  const marketplaceRef = marketplace as MarketplaceRef
  const updateScreenWidth = () => {
    setScreenWidth(window.document.body.clientWidth)
  }
  const { loggedInUser } = getloggedInUser();

  // const dispatch: any = useDispatch();
  // const history = useHistory<{ date?: string, queryParams?: object } | null>();
  const { search } = useLocation()
  const newMeetingRef = useRef<MeetingDetailsPopUpRef | null>(null);

  const {time_zone: companyTimeZone } = JSON.parse(localStorage.getItem('company') || '{}');
  const companyCurentTime = companyTimeZone && dayjs()?.tz(`${companyTimeZone}`)?.format('hh:mm:ss');

  const { from, to } = queryString?.parse(search) || {};

  const [updateMeeting] = useState<AdminDeliveryRequest | null>(null);
  const [range] = useState<Range[]>([{
    // Convert (from and to) date params to company timezone specific date and time.
    startDate: from ? (companyCurentTime ? new Date(dayjs(`${from} ${companyCurentTime}`)?.format('ddd MMM DD YYYY hh:mm:ss')) : new Date(`${from}`)) : new Date(),
    endDate: to ? (companyCurentTime ? new Date(dayjs(`${to} ${companyCurentTime}`)?.format('ddd MMM DD YYYY hh:mm:ss')) : new Date(`${to}`)) : addDays(new Date(), 6),
    key: 'selection',
  }]);
  useEffect(() => {
    dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    });
  }, [dispatch])

  // useEffect(() => {
  //   dispatch(getAnnouncements())
  //   document.title = 'Dashboard | Chowmill';
  //   dashboardTranslations();
  //   if (localStorage.getItem('order_id')) {
  //     ratingPopUp?.current?.openModal();
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   if (history?.location?.state?.date) {
  //     newMeetingRef?.current?.openModal('', 'create');
  //     history.push({ pathname: '/dashboard', state: null })
  //   }
  // }, [history, search])

  const updateDeliveryRequestsHandler = () => {
    const { type, order } = queryString?.parse(search, queryFormat) || {};
    const apiParams = `&sort_by=${(type && type[1]) || 'delivery_at'}&sort_order=${(order && order[1]) || 'ASC'}`
    dispatch(getMeetingsByRange('delivery_requests', dayjs(range[0].startDate).format('D-M-YYYY'), dayjs(range[0].endDate).format('D-M-YYYY'), apiParams));
  }
  const onLogoutClick = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e?.preventDefault();
    dispatch(logoutUser(history));
    return false;
  }

  const showDeliveryDetailsHandler = () => {
    if(currentDelivery?.status === "approved") {
      // parentComponentRef.setState({ showDeliveryOrdersSidebar: true, isOpenSidebar: false })
      showDeliveryOrdersSidebar?.(true)
      isOpenSidebar?.(true)
    } else {
      showCart?.(true)
      // parentComponentRef.setState({ showCart: true })
    }
  }

  const closeModal = () => {
    setEnterprizePopUp(false);
  }
  const closeEnterpriseConfirmationPopUp = () => {
    setEnterprizeConfirm(false);
    setEnterprizePopUp(false);
  }
  const openConfirmEnterprizePopUp = () => {
    setEnterprizePopUp(false);
    setEnterprizeConfirm(true);
    // setEnterprizeEmailSent(true);
  }
  const openEnterprizeModal = () => {
    setEnterprizePopUp(true);
  }

  const scheduleMarketplace = () => {
    // const {time_zone} = user
    let document: Document = window.document;
    const marketplace = JSON.parse(localStorage.getItem('company') || '{}')?.enable_marketplace;
    // const headers = JSON.parse(localStorage.getItem('headers') || '{}')
    const pathName = history.location.pathname.split('/');
    let dateParam = date || dayjs().format('M-D-YYYY'); // If not on Menu Page then Set to Today Date.

    // let zonedDateTime = new Date(new Date().toLocaleString("en-US", { timeZone: time_zone }))
    // let thresholdDateTime = new Date(dayjs(new Date()).format('ddd MMM D YYYY') + ' 14:00:00')

    const marketplaceDate = getDateForMarketplace()
    let dateForMarketplace = dayjs(dateParam, 'M-D-YYYY') <= dayjs() ?
      // date for marketplace with threshold: 2pm
      // dayjs().add((dayjs(zonedDateTime).isAfter(new Date(thresholdDateTime))? 2 : 1), 'day').format('M-D-YYYY')
      marketplaceDate
      : dateParam

    // if (this.props.fromPublicMarketPlace && !isEmpty(headers)) {
    //   // this.props.history.push({pathname: `//date`})
    //   this.props?.parentComponentRef?.setState({showCart: true})
    // }
    // clearing cartData if it is checkedout
    if (cart_at_login?.status !== 'pending') {
      dispatch(clearOldCart())
    }
    
    if (pathName?.length !== 3) {
      if (pathName[1] === 'menu') { // On Menu Detail Page.
        if (marketplace && document.getElementById('scheduleClick')) {
          document.getElementById('scheduleClick')?.click();
          history.push({ pathname: `/menu/${marketplaceDate}/` });
        } else if (fromPublicMarketPlace) {
          // history.push({ pathname: `/home/${dateForMarketplace}/xyz`, state: { date: dateForMarketplace } });
        } else {
          newMeetingRef.current?.openModal()
          // history.push({ pathname: `/dashboard`, state: { date: dateForMarketplace } });
        }
      } else { // Neither on Menu Detail Page Nor On Dashboard Page.
        if (marketplace) {
          history.push({ pathname: `/menu/${dateForMarketplace}/` });
        } else {
          history.push({ pathname: `/`, state: { date: dateForMarketplace } });
        }
      }
    } else {
      const button = document.getElementById('back-to-marketplace-btn');
      if (button) {
        window.scroll(0,0)
        button.click();
      }
    }
  }

  // Redirecting function for login And SignUp popUps
  const signupLoginRedirect = (page: string) => {
    if (page === 'login') {
      setIsOpenLeftSideBar(false);
      setModalState({type: 'signup', isOpen: true})
    } else if (page === 'signup') {
      setIsOpenLeftSideBar(false);
      setModalState({type: 'login', isOpen: true})
    }
  }

  useEffect(() => {
    window?.addEventListener('resize', updateScreenWidth);
    return () => window?.removeEventListener('resize', updateScreenWidth);
  }, [])

  const navbarLinksList = [
    ...(isAdmin ? [{
      label: 'New Delivery',
      onClickHandler: scheduleMarketplace,
      isNavLink: false,
      icon: <i className="fas fa-plus-circle nav-store-icon"></i>,
      iconClass: 'fas fa-plus-circle',
      className: `btn btn-sm btn-custom-primary new-delivery d-flex align-items-center ${scheduleMarketplaceDelivery ? 'active' : ''}`
    }] : []),
    ...(menu?.menu_tab_meeting ? [{
      label: 'Your Orders',
      isNavLink: true,
      iconClass: 'fas fa-hamburger',
      onClickHandler: () => { },
      path: `/menu/${dayjs(menu?.menu_tab_meeting?.delivery_at)?.format('M-D-YYYY')}/${menu?.menu_tab_meeting?.slug}`,
      className: `menu-detail-page px-3 ml-2 ${['menu'].includes(pathName) && !scheduleMarketplaceDelivery ? 'active' : ''}`
    }] : []),
    // ...(!this.props?.fromPublicMarketPlace ? [
    // {
    //   label: 'Order History',
    //   isNavLink: true,
    //   iconClass: 'fas fa-calendar-alt',
    //   path: `/order-history`,
    //   className: `order-history mr-4 ${(!menu_tab_meeting) ? 'ml-3' : ''} ${pathName === 'orders' || pathName === 'order-history' ? 'active' : ''}`,
    // }] : []),
    // ...(isAdmin ? [
    //   {
    //     label: 'Reports',
    //     isNavLink: true,
    //     iconClass: 'fas fa-chart-pie',
    //     path: `/reports`,
    //     className: `reports px-2 ${pathName === 'reports' ? 'active' : ''}`,
    //     newBadge: false
    //   }] : []),
  ]

  const adminLinks = (
    <>
      {company?.self_signup === false && <MenuLink
        classNames='account'
        icon='fas fa-building'
        path='/account'
        label='Account'
      />}
      {company?.self_signup === false && (user?.allow_admin_to_manage_users ? <MenuLink
        classNames='users'
        icon='fas fa-users'
        path='/users'
        label='Users'
      /> : '')}
      <MenuLink
        classNames='invite-vendors'
        icon='fas fa-store-alt'
        label='Invite Vendors'
        onClick={() => { setIsOpenLeftSideBar(false); setModalState({type: 'inviteVendor', isOpen: true}); }}
      />
      {/* <MenuLink
        classNames='signup'
        icon='fas fa-store-alt'
        label='Invite Vendors'
        onClick={() => { this.setState({ isOpenLeftSideBar: false }); this.marketPlaceSignUp?.(); }}
      /> */}
    </>
  );
  const userLinks = (
    <>
      <MenuLink
        classNames='profile'
        icon='fas fa-user'
        path='/profile'
        label='Profile'
      />
      {isAdmin && <MenuLink
        classNames='reports'
        icon='fas fa-tachometer-alt'
        path={`/reports`}
        label='Reports'
      />}
    </>
  );
  const bottomLinks = (
    <>
      <MenuLink
        classNames='logout'
        icon='fas fa-sign-out-alt'
        path='/signin'
        label='Logout'
        onClick={onLogoutClick}
      />
    </>
  );

  const userDropdownLinks = <>
    <div className='dropdown-item profile-dropdown-header-item py-3 px-5 text-center'>
      <img
        aria-hidden
        alt='Company Logo'
        className='user-image m-3 '
        src={(company && company.image && company.image.url) ? company.image.url : '/imgs/userprofile.jpg'}
      />
      <br />
      <strong className='text-capitalize'>
        {user.first_name ? user.first_name : user.name} {user.last_name ? user.last_name : user.name}
      </strong>
      <br />
      <small className='muted'>{user.email}</small>
    </div>
    <div className='dropdown-divider' />
    <div
      onClick={e => { e.stopPropagation(); setIsOpenLeftSideBar(false) }}
    >
      {/* This Link is added here manually to be at the top of list */}
      {/* <MenuLink
        classNames='dashboard'
        icon='fas fa-tachometer-alt'
        path={`/dashboard${searchParams?.search || ''}`}
        label='Dashboard'
      /> */}
      <MenuLink
      classNames='orderHistory'
      icon='fas fa-calendar-alt'
      path={`/order-history`}
      label='Order History'
      // classNames=`${pathName === 'orders' || pathName === 'order-history' ? 'active' : ''}`,
      />
      {screenWidth <= 992 && navbarLinksList?.map((navLink, index) => <React.Fragment key={navLink?.label}>
        {(isAdmin && index === 0 && toggleMenuDetailSideBar) ? '' :
          <MenuLink
            classNames={''}
            icon={navLink?.iconClass}
            path={navLink?.path || null}
            label={navLink?.label}
            onClick={navLink?.onClickHandler}
          />}
      </React.Fragment>)
      }
      {userLinks}
      {isAdmin ? adminLinks : ''}
      {bottomLinks}
      <div className='dropdown-divider' />
      <div className='p-2 px-3'>
        <a className='dropdown-item profiledropdownitem text-secondary d-inline p-0 privacy-policy'
          href='https://chowmill.com/privacy-policy/'
          target='_blank' rel='noopener noreferrer'
          style={{ 'textDecoration': 'underline' }}>
          Privacy
        </a>
        <a className='dropdown-item profiledropdownitem text-secondary d-inline p-0 ml-3 terms-of-service'
          href='https://chowmill.com/terms-of-service/'
          target='_blank'
          rel='noopener noreferrer'
          style={{ 'textDecoration': 'underline' }}>
          Terms
        </a>
      </div>
    </div>
  </>

  return (
    <React.Fragment>
      <nav className={`navbar px-3 navbar-light bg-white border-bottom fixed-top px-3 py-3 px-md-0 ${(Object.keys(user).length === 0)&& "non-loginuser-header py-3"}`} style={{ zIndex: 5 }}>
        <div className="container px-0">
          {(fromPublicMarketPlace) || ((showLeftSidebarToggler) && screenWidth <= 992 &&
            <button className='btn d-flex align-items-center' onClick={toggleMenuDetailSideBar}>
              <i className='fas fa-bars' style={{ fontSize: 25 }} />
              {!invitedMeeting && !scheduleMarketplaceDelivery && <span className='all-deliveries-count ml-2' style={{ width: 25, height: 25 }}>{totalDeliveriesOnSingleDate || 0}</span>}
            </button>)
          }
          {/* <div className={menu?.menu_tab_meeting === undefined ? 'pointer-none' : ''}>
            <Link className={'navbar-brand pr-3 mr-4'} to={logoRedirectUrl}>
              <img alt='Chowmill' src='/imgs/chowmill_icon.svg' className='logo-chowmill' /> <span className='logo-chowmill-t'>Chowmill</span>
            </Link>
          </div> */}

          <div>
  <Link className={'navbar-brand pr-3 mr-4'} to={logoRedirectUrl}>
    <img alt='Chowmill' src='/imgs/chowmill_icon.svg' className='logo-chowmill' /> <span className='logo-chowmill-t'>Chowmill</span>
  </Link>
</div> 

          {invitedMeeting !== true && user && screenWidth > 992 ?
            <div className='flex-grow-1' id='chowmillNavbarPrim'>
              <ul className='d-flex'>
                {navbarLinksList?.map(({ isNavLink, icon, className, path, label, onClickHandler }) => <React.Fragment key={label + `${path}`}>
                  {(isNavLink && path) ?
                    <Link className={`nav-link prim-link p-2 ${className}`} to={path}>
                      {i18n && i18n.t(label)}&nbsp;
                      {/* {newBadge && <span className='badge badge-danger'>  New</span>} */}
                    </Link> :
                    <button className={`nav-link prim-link ${className}`}
                      onClick={() => { onClickHandler?.() }}
                      style={{ borderRadius: 8 }}
                    >
                      {icon || ''} &nbsp; {i18n && i18n.t(label)}</button>}
                </React.Fragment>)
                }
              </ul>
            </div>
            : ''}
            {/* // SignUP Login for Public Market Place */}
            {show_searchbar && !marketplaceRef?.state?.viewRestaurantMenu && <div className="col-lg-4 d-none d-lg-block px-0">
              <div className="has-search">
                <span className="fa fa-search form-control-feedback pl-3 pr-2" id='searchRestaurantButton'
                  onClick={() => marketplaceRef?.searchRestaurants(false)}>
                  </span>
                <input type="search" className="form-control rounded-pill position-relative restaurant-search-bar" placeholder="Search Food, Restaurants, Cuisines" id='searchRestaurant' name='searchRestaurant' onKeyPress={(e) => marketplaceRef?.keyPressed(e)} onChange={(e) => marketplaceRef?.onChange(e)}/>
                <div className="header-searchbar-pills d-flex searchBarScroll">
                {parentComponentRef?.state?.selectedTypeheadPill?.map((typeahead: string, index: number) => (typeahead ? <span key={index} className='badge badge-pill custome-gray-badge px-3 py-2 py-md-1'>
                  {typeahead} <i className='ml-2 fas fa-times cursor-pointer' onClick={() => marketplaceRef?.closeSearch(typeahead)}></i></span> : ''))}
                </div>
              </div>
            </div>}
            {/* Enterprise nav icons */}
             {/* <div className="d-flex align-items-center mx-3">
              <button className='btn upgrade-btn rounded-pill d-none d-md-block'>
                <img src="/imgs/crown.svg" alt="crown" className='pr-2' />Upgrade
              </button>
             </div> */}
              <div className={`d-none d-md-inline-flex align-items-center mx-2 ${!isEmpty(loggedInUser) && company?.enterprise_status === 'converted' ? '' : (!isEmpty(loggedInUser)) ? 'btn upgrade-btn rounded-pill' : ''}`}>
              {/* {screenWidth > 992  && <> 
                {!isEmpty(loggedInUser) && company?.enterprise_status !== 'requested' ? <span><img src="/imgs/crown.svg" alt="crown" /></span>
                  :
                  <div>
                  {!isEmpty(loggedInUser) && <span style={{ 'opacity': '30%' }}><img src="/imgs/crown.svg" alt="crown"/></span>}
                  </div>
                }
              </>
              } */}
              {company && company?.enterprise_status !== 'converted' && company?.self_signup === true && screenWidth > 992  && <button className='nav-link btn upgrade-btn rounded-pill menu-detail-page cursor-pointer py-0' data-tip data-for='RequestAlreadySent'>
                {company?.enterprise_status === 'not_converted' ? <span onClick={openEnterprizeModal}>Upgrade</span>
                  :
                  <>
                    {(!converted_to_enterprize || company?.enterprise_status === 'requested') && <span style={{ 'opacity': '30%' }}>Upgrade</span>}
                  </>
                }
              {company?.enterprise_status === 'requested' && window.navigator.userAgent.match(/MSIE|Trident/) === null && // check if not IE
                <ReactTooltip place='bottom' type='dark' id='RequestAlreadySent' effect='solid' className='react-tooltip' >
                  <span>
                    Request already sent
                  </span>
                </ReactTooltip>
              }
              </button>
              }
            </div>
            {screenWidth >= 440 ?
              <React.Fragment>
                {fromPublicMarketPlace && <div className='text-nowrap profilelink position-relative cursor-pointer ml-auto'
                  onClick={() => { setIsOpenLeftSideBar(false); setModalState({type: 'login', isOpen: true}); }}
                // onClick={() => { this.setState({ isOpenLeftSideBar: true }) }}
                >
                  {/* <div id='autoThumbnailParent'>
                    <div id='autoThumbnailChild'> */}
                  <button className='btn login-btn rounded-pill'>
                    {/* <span className='hidden-xs text-capitalize' id='autoHiddenDrop'>Login</span> */}
                    <img src="/imgs/SVGs/user-login-icon.svg" id='autoHiddenDrop' className='user-login-icon' alt="customer-login"></img>
                  </button>
                  {/* </div>
                  </div> */}
                </div>}
              </React.Fragment> :
              <React.Fragment>
                {fromPublicMarketPlace && <div className='cursor-pointer ml-auto mr-2' onClick={() => { setIsOpenLeftSideBar(false); setModalState({type: 'login', isOpen: true}); }}>
                  <img src='/imgs/SVGs/nonlogged-in.svg' alt="customer-login"/>
                </div>}
              </React.Fragment>
            }
            {/* Cart icon for non-logged in users */}
            {((fromPublicMarketPlace) || (company?.self_signup === true)) && screenWidth < 3600 &&
              <div className='d-flex delivery-details-cart shopping-cart cursor-pointer' onClick={() => showCart?.(true)}>
                {cartDetails?.itemCount > 0 && <span className="mobile-basket-icons-wrapper position-relative ml-2">
                  <i className="fas fa-shopping-cart"></i>
                  <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position-nonglogin border border-white'>{cartSummaryItemsCount ?? 0}</span>
                </span>
                }
              </div>
            }
            {/* // cart */}

            {/* {// Removed the CART icon from header Temporarily //} */}
            {((fromPublicMarketPlace) || (company?.self_signup === true)) && false && !hideHeaderCart &&
              <div className='d-flex delivery-details-cart shopping-cart'>
                <span className="icon-wrapper-large position-relative shopping-bag ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="33.003" viewBox="0 0 29.002 33.003">
                    <path id="bag_new" data-name="bag new" d="M7856,13129a6.508,6.508,0,0,1-6.5-6.5v-15a3.506,3.506,0,0,1,3.5-3.5h4.5v-1.5a6.5,6.5,0,0,1,13,0v1.5h4.5a3.507,3.507,0,0,1,3.5,3.5v15a6.508,6.508,0,0,1-6.5,6.5Zm-4.5-21.5v15a4.508,4.508,0,0,0,4.5,4.5h16a4.507,4.507,0,0,0,4.5-4.5v-15a1.505,1.505,0,0,0-1.5-1.5h-4.5v5a1,1,0,0,1-2,0v-5h-9v5a1,1,0,0,1-2,0v-5H7853A1.5,1.5,0,0,0,7851.5,13107.5Zm8-5v1.5h9v-1.5a4.5,4.5,0,0,0-9,0Z" transform="translate(-7849.5 -13095.998)" fill="#dc3644"/>
                  </svg>
                  <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position'>{cartDetails?.itemCount ?? 0}</span>
                </span>
                {/* <div className='pl-3'>
                  <p className='fs-18 fw-normal text-gray-600 m-0'>{`$${this.props.cartSummary.total ?? '0'}`}</p>
                  {this.props?.cartDetails?.itemCount ?
                    <span className="fs-18 fw-normal text-decoration-none" onClick={() => this.props.parentComponentRef.setState({ showCart: true })}>
                      View Cart
                    </span>
                  :
                    <span className="fs-18 fw-normal text-decoration-none">
                      Cart Empty
                    </span>
                  }
                </div> */}
              </div>
            }
            {/* Enterprise nav icons */}
            {/* <div className="align-items-center d-none d-md-inline-flex">
            {(isAdmin || (this.user?.ss_user === true)) && screenWidth > 992 &&<>
              {company?.enterprise_status !== 'requested' ? <span><img src="/imgs/crown.svg" alt="crown" /></span>
                :
                <span style={{ 'opacity': '30%' }}><img src="/imgs/crown.svg" alt="crown"/></span>
              }
            </>
            }
            {company && company?.enterprise_status !== 'converted' && company?.self_signup === true && screenWidth > 992  && <button className='nav-link bg-transparent border-0 p-2 pl-0 menu-detail-page d-flex align-items-center cursor-pointer fs-18 fw-600' style={{ color: '#007bff' }} title='Request already sent'>
              {company?.enterprise_status === 'not_converted' ? <span onClick={this.openEnterprizeModal}> Upgrade to Enterprise Plan</span>
                :
                <>
                  {(!this.props?.converted_to_enterprize || company?.enterprise_status === 'requested') && <span style={{ 'opacity': '30%' }}> Upgrade to Enterprise Plan</span>}
                </>
              }
            </button>
            }
            </div> */}
          {!fromPublicMarketPlace && !invitedMeeting &&
            <div className='nav-link text-nowrap profilelink position-relative cursor-pointer d-flex py-0 pr-0'>
              {(cart_display_check || pathCheckForCart[3])&& cart_at_login?.orders_quantity > 0  && <span className='mobile-basket-icons-wrapper d-lg-none mr-2 mt-1' onClick={() => {showDeliveryDetailsHandler()}}>
                <i className="fas fa-shopping-cart text-white"></i>
              </span>}
              <div id='autoThumbnailParent'>
                {/* {company?.self_signup === true ? (company?.enterprise_status === 'converted' && screenWidth < 992 && <span className='rounded-circle position-absolute icon-wrapper-badge-position'><img className='mobile-crown' src="/imgs/crown.svg" alt="crown" /></span>) : (isAdmin && screenWidth < 992 && <span className='rounded-circle position-absolute icon-wrapper-badge-position'><img className='mobile-crown' src="/imgs/crown.svg" alt="crown" /></span>)} */}
                <div id='autoThumbnailChild' onClick={() => { setIsOpenLeftSideBar(true) }}>
                  <span className='hidden-xs text-capitalize' id='autoHiddenDrop'>{user?.first_name?.charAt(0)} </span>
                </div>
              </div>
              {isOpenLeftSideBar && <Backdrop onClick={() => setIsOpenLeftSideBar(false)} />}
              {screenWidth > 992 ?
                <>
                  {isOpenLeftSideBar &&
                    <div className='dropdown-menu-right profile-dropdown bg-white position-absolute' style={{ borderRadius: '17px' }} id='profile' aria-labelledby='navbarDropdown'>
                      {userDropdownLinks}
                    </div>}
                </> :
                <div className='right-sidebar' style={{ transform: isOpenLeftSideBar ? 'translateX(0)' : 'translateX(100%)' }}>
                  {userDropdownLinks}
                </div>
              }
            </div>
          }
        </div>
      </nav>
      {/* <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-2 ml-md-auto'>
            <div className='dropdown-menu'>
              <form >
                <div className='form-group'>
                  <label htmlFor='exampleDropdownFormEmail1'>Email address</label>
                  <input type='email' className='form-control' id='exampleDropdownFormEmail1' placeholder='email@example.com' />
                </div>
                <div className='form-group'>
                  <label htmlFor='exampleDropdownFormPassword1'>Password</label>
                  <input type='password' className='form-control' id='exampleDropdownFormPassword1' placeholder='Password' autoComplete='false' />
                </div>
                <div className='form-check'>
                  <input type='checkbox' className='form-check-input' id='dropdownCheck' />
                  <label className='form-check-label' htmlFor='dropdownCheck'>
                    Remember me
                  </label>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary'
                >
                  Sign in
                </button>
              </form>
              <div className='dropdown-divider'></div>
              <Link className='dropdown-item' to='/'>New around here? Sign up</Link>
              <Link className='dropdown-item' to='/'>Forgot password?</Link>
            </div>
          </div>
        </div>
      </div> */}
      <InviteVendorPopUp
        // onRef={(openModalRef: (() => void) | null) => { inviteVendor = openModalRef }}
        closeModal={() => setModalState({type: null, isOpen: false})}
        isOpenModal={modalState.type === 'inviteVendor'? modalState.isOpen : false}
      />
      {fromPublicMarketPlace &&
      <>
        <SignUpWidget
          isMarketPlaceSignUp
          signupLoginRedirect={signupLoginRedirect}
          // isOpenSignup
          // onRef={(openModalRef: (() => void) | null) => { marketPlaceSignUp = openModalRef }}
          // closeSignup={() => { this.setState({ showSignupPopup: false }); document.title = 'Menu | Chowmill'; }}
          closeModal={() => setModalState({type: null, isOpen: false})}
          isOpenModal={modalState.type === 'signup'? modalState.isOpen : false}
        />
        <Login
          // {...this.props}
          isMarketPlaceLogin
          signupLoginRedirect={signupLoginRedirect}
          // onRef={(openModalRef: (() => void) | null) => { marketPlaceLogin = openModalRef }}
          closeModal={() => setModalState({type: null, isOpen: false})}
          isOpenModal={modalState.type === 'login'? modalState.isOpen : false}
        />
      </>}
      {enterprizePopUp && <EnterprizePlanViewerPopUp
      modalIsOpen={enterprizePopUp}
      closeModal={closeModal}
      confirmAction={openConfirmEnterprizePopUp}
      />}
      {enterprizeConfirm && <EnterprizeConfirmationPopUp
        modalIsOpen={enterprizeConfirm}
        closeModal={closeEnterpriseConfirmationPopUp}
        confirmAction={() => { }}
      />}
      <MeetingDetailsPopUp
          onRef={(ref: MeetingDetailsPopUpRef | null) => { newMeetingRef.current = ref }}
          selectedDate={dayjs(updateMeeting?.delivery_at || addDays(new Date(), 1)).toDate()}
          onUpdatePage={updateDeliveryRequestsHandler}
          createSchedular = {true}
        />
    </React.Fragment>
  )
}

export default Header;