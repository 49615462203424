// Packages
import React, { Component, createRef } from 'react';
// import $ from 'jquery'; // reload by eventlistner
import dayjs from 'dayjs';
// import ActionCable from 'actioncable';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
// React Components
import MenuArea from '../Common/MenuArea';
import CloneOrders from '../Common/CloneOrders';
import MenuFilters from '../Common/MenuFilters';
// import Spinner from '../../../Common/Spinners/Spinner';
import Footer from '../../../Common/HeaderFooter/Footer';
import Header from '../../../Common/HeaderFooter/Header';
import DeliveryDetailsSection from '../Common/DeliveryDetails';
import DeliveryDatesCalendar from '../Common/DeliverDatesCalendar';
import AnnouncementsAndAlerts from '../Common/AnnouncementsAndAlerts';
import DeliveryDetailsForMobiles from '../Common/DeliveryDetailsForMobiles';
import OrderPopup, { OrderPopupRef } from '../../../Common/Popups/OrderPopup';
import Marketplace, { MarketplaceRef } from '../MarketplacePreview/Marketplace';
import RatingPopUp, { RatingPopUpRef } from '../../../Common/Popups/RatingPopUp';
import DeliveryOrdersSidebar from '../../../Common/Popups/DeliveryOrdersSidebar';
import CancelMeeting from '../../../Common/Popups/CancelMeetingPopUp';
import ShareMeetingPopUp, { ShareMeetingPopUpRef } from '../../../Common/Popups/ShareMeetingPopUp';
import MeetingDetailsPopUp, { MeetingDetailsPopUpRef } from '../../../Common/Popups/MeetingDetailsPopUp';
import { MenuFiltersLoading, MenuFoodItemsLoading } from '../../../Common/Loadings/MenuPage/MenuDetailLoadings';
// Redux Actions
import { getOrderDetails } from '../../../Actions/orderActions';
import { getAnnouncements } from '../../../Actions/userActions';
import { getCompanyForOrderPurpose } from '../../../Actions/companyActions';
import { MARKETPLACE_PAGE, SET_POPUP_STATE_ON_MENU_DETAIL_PAGE } from '../../../Actions/types';
import { getShowMenuTab, getSingleDeliveryMenu } from '../../../Actions/menuActions';
import { getDeliveryDates, getSingleSchedule } from '../../../Actions/scheduleDeliveryActions';
import { getDynamicTitles } from '../../../Actions/marketplaceActions';
// Utitlities
import store from '../../../store';
import isEmpty from '../../../Utilities/is_empty';
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
import { getPricesToShown } from '../../../Utilities/getPricesToShown';
import { getResponsiveNoOfSlides } from '../../../Utilities/getResponsiveNoOfSildes';
import { dashboardTranslations } from '../../../Translations/dashboardTranslations';
// Types
import { Cuisine } from '../../../Interfaces/PayloadsAndResponses/Cuisines';
import { GetAnnouncements } from '../../../Interfaces/PayloadsAndResponses/Users';
import { LocalStorageCompany, User, UserType } from '../../../Interfaces/PayloadsAndResponses/Auth';
import { BuffetMenuRstaurantSection, DeliveryMenuRestaurant, FoodItem, MenuFilter, RecentOrder } from '../../../Interfaces/PayloadsAndResponses/Menu';
import { Delivery, SchedualeRequest, DeliveryOnSingleDate } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import { GetMenuFilters, Menu, OrderDetails, ReduxRootState, SchedulesDetails, Users } from '../../../Interfaces/ReduxInterface/ReduxRootState';
// CSS
import 'animate.css';
import '../CSS/custom.css';
import 'rc-steps/assets/index.css';
// import 'rc-steps/assets/iconfont.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import EmailVerification from '../../Invites/EmailVerificationInvite/EmailVerification';
import { getCartAfterLogin } from '../../../Actions/cartActions';
import Cart from '../../Cart/Cart';
import EnterprizeConfirmationPopUp from '../../../Common/Popups/EnterprizeConfirmationPopUp';
import EnterprizePlanViewerPopUp from '../../../Common/Popups/EnterprizePlanViewerPopUp';
import MarketplaceScheduleDelivery from '../MarketplacePreview/MarketplaceScheduleDelivery';
import Spinner from '../../../Common/Spinners/Spinner';

type Params = { date: string, meetingSlug?: string, menu_type: string | undefined, address_id: string };

type ApprovedMeetingMenuDetailProps = {
  i18n: WithTranslation | null;
  menu: Menu;
  menu_filter_redux: null | GetMenuFilters;
  user: Users;
  order: OrderDetails;
  cuisines: Cuisine[] | null;
  announcements: GetAnnouncements;
  schedules_details: SchedulesDetails;
  schedule_update_request: SchedualeRequest | null;
  converted_to_enterprize?: boolean;
  // seledtedAddressId:any;
  getCompanyForOrderPurpose: () => void;
  getDynamicTitles: (dynamicTitle: string | null, seoRedirectfun?:()=>void) => void;
  getShowMenuTab: () => void;
  getAnnouncements: () => void;
  //getCartAfterLogin: (date: any) => void;     //now slug is not optional so calls without slugs are stopped and optional slug in api call is also changed 
  getOrderDetails: (slug: string) => void;
  getSingleSchedule: (slug: string) => void;
  getDeliveryDates: (from?: string, to?: string) => void;
  getSingleDeliveryMenu: (scheduleSlug: string, menu_type: string, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], token?: string, filterPurpose?: boolean) => void;
  scrollHandling?: ((divPositionCheck: boolean) => void) | undefined;
} & RouteComponentProps<Params>;

export type ApprovedMeetingMenuDetailState = {
  cancelled: boolean;
  screenWidth: number,
  cloneOrder: boolean;
  isOpenSidebar: boolean;
  showDeliveryOrdersSidebar: boolean;
  companyData: null | LocalStorageCompany,
  menu_type: string | undefined,
  address_id: number | undefined;
  field_required: boolean | undefined;
  scheduleMarketplaceDelivery: boolean;
  currentMeeting: Delivery | null | undefined;
  showCart: boolean;
  hideHeaderCart: boolean;
  converted_to_enterprize?: boolean;
  enterprizeConfirm: boolean,
  enterprizePopUp: boolean,
  enterprizeEmailSent: boolean,
  localStorageData: boolean;
  cancelDeliveryState : boolean;
  stickyDivRef: null | number;
  restaurantHeaderCheck : null | boolean;
  divPositionCheck: boolean;
  yourOrderHeaderPaddingCheck: boolean;
  selectedTypeheadPill: Array<string>;
  menuFiltersFromRedux: MenuFilter[] | null | undefined;
}

class ApprovedMeetingMenuDetail extends Component<ApprovedMeetingMenuDetailProps, ApprovedMeetingMenuDetailState> {
  orderPopup = createRef<OrderPopupRef>().current;
  shareMenu = createRef<ShareMeetingPopUpRef>().current;
  ratingPopUp = createRef<RatingPopUpRef>().current;
  marketplace = createRef<MarketplaceRef>().current;
  meetingPopup = createRef<MeetingDetailsPopUpRef>().current;
  // cancelMeeting = createRef<CancelMeetingPopUpRef>().current;
  user = JSON.parse(localStorage.getItem('user') || '{}') as User;
  company = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;
  localStorageCompany = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;
  initialPrice = 0;
  stickyDivRef = createRef<HTMLDivElement>();
  restaurantHeaderCheck = createRef<HTMLDivElement>();
  private timeoutId: any = null;

  state: ApprovedMeetingMenuDetailState = {
    menu_type: '',
    cancelled: true,
    address_id: undefined,
    cloneOrder: false,
    companyData: null,
    currentMeeting: null,
    isOpenSidebar: false,
    field_required: undefined,
    showDeliveryOrdersSidebar: false,
    scheduleMarketplaceDelivery: false,
    screenWidth: window.document.body.clientWidth,
    showCart: false,
    hideHeaderCart: false,
    enterprizeConfirm: false,
    enterprizePopUp: false,
    enterprizeEmailSent: false,
    localStorageData: this.localStorageCompany?.enterprise_status === 'not_converted' ? true : false,
    converted_to_enterprize: false,
    cancelDeliveryState: false,
    stickyDivRef: null,
    restaurantHeaderCheck: null,
    divPositionCheck: false,
    yourOrderHeaderPaddingCheck: false,
    selectedTypeheadPill: [],
    menuFiltersFromRedux: this.props.menu_filter_redux?.menu_filters,
  }
  // ----  removed the ActionCable completely ---- //
  // handleDriverTask = (receivedTask: any) => {
  //   if (parseFloat(this.props.match.params.meetingSlug || '') === receivedTask.data.slug) {
  //     let task = receivedTask.data;
  //     let meeting: any = this.state.currentMeeting;
  //     meeting.driver = task.driver;
  //     meeting.addresses = task.addresses;
  //     meeting.destination = task.destination;
  //     this.setState({ currentMeeting: meeting });
  //   }
  // }

  updateScreenWidth = () => {
    this.setState({ screenWidth: window.document.body.clientWidth })
  }

  hideCartOnHeader = () => {
    this.setState({hideHeaderCart: true})
  }

  openCancelMeetingModal = () => {
    this.setState({cancelDeliveryState: true})
  }

  closeCancelMeetingModal = () => {
    this.setState({cancelDeliveryState: false})
  }
  componentDidMount() {
    const newWindow = localStorage?.getItem('newwindow')
    if (newWindow === 'true') {
      window.location.reload()
      localStorage.removeItem('newwindow')
    }
    const { getAnnouncements, getSingleSchedule, getSingleDeliveryMenu, getCompanyForOrderPurpose, getShowMenuTab, history,
      menu: { menu_tab_meeting_loading },
      match: { params: { address_id, date = null, meetingSlug = null } },
    } = this.props
    // getDeliveryDates();
    //Get Announcements for Menu detail page.
    getAnnouncements();
    store.dispatch({
      type: MARKETPLACE_PAGE,
      payload: false
    })
    
    const { isCompanyAdmin } = getloggedInUser()
    if (isCompanyAdmin) {
      const queryString = window.location.search;
      // Create a URLSearchParams object
      const paramsDataData = new URLSearchParams(queryString);
      const titleValue = paramsDataData.get('k');

      if (!isEmpty(titleValue)) {
        localStorage.setItem('SEOTitle', JSON.stringify(titleValue))
        this.props.getDynamicTitles(titleValue)
      } else {
        this.props.getDynamicTitles('')
      }
    }
    //now slug is not optional so calls without slugs are stopped and optional slug in api call is also changed 
    //if (this.company.self_signup === true) {
      //const dateOfLogin = new Date();
      //const formattedDate = dateOfLogin.toISOString();
      //getCartAfterLogin(formattedDate)
    //}
    //To handle if user directly enter url of ApprovedMeetingMenuDetail component.
    if (meetingSlug && date) { // Complete URL of Menu Detail Page is Present
      getSingleSchedule(meetingSlug);
      getSingleDeliveryMenu(meetingSlug, 'lunch');
      if (localStorage.getItem('order_id')) {
        this.ratingPopUp?.openModal();
      }
    } else if (!meetingSlug && date && dayjs(date ?? '', 'M-D-YYYY').isValid()) { // Marketplace Page URL
      if (this.user?.user_type === UserType.ADMIN && this.company?.enable_marketplace) {
        this.scheduleMarketplace();
      } else {
        !menu_tab_meeting_loading && getShowMenuTab(); // Only call this API if it is not called earlier from App.js
      }
    } else if (!meetingSlug && !date) {
      !menu_tab_meeting_loading && getShowMenuTab(); // Only call this API if it is not called earlier from App.js
    }
    getCompanyForOrderPurpose();
    dashboardTranslations();


    if (0) {
      // $(window).bind('popstate', function () {
      //   window.location.reload();
      // });
      //javascript equivalent for jquery we can handle page refresh by state
    }
    window.addEventListener('popstate', function () {
      window.location.reload();
    });
    
    if (address_id === null) {
      history.push('/signup');
      return;
    }
    document.title = 'Menu | Chowmill';
    window?.addEventListener('resize', this.updateScreenWidth);

    // On Escape close sidebar
    const handleKeyDown = (e: any) => {
      if(e.key === 'Escape'){
        this.setState({ showDeliveryOrdersSidebar: false })
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    document.addEventListener('scroll', this.handleScroll);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }

  componentDidUpdate(prevProps: ApprovedMeetingMenuDetailProps) {

    const freshStorageData: any = localStorage.getItem('apc_user_id')
    if (isEmpty(freshStorageData)) {
      window.location.replace('/signin')
    }
    const { isCompanyUser, isCompanyAdmin, loggedInUserCompany: { enable_marketplace = false, self_signup } = {} } = getloggedInUser();
    const { match: { params: { meetingSlug, date } }, menu: { menu_tab_meeting }, history, getSingleSchedule, getSingleDeliveryMenu } = this.props || {};
    const { match: { params: { meetingSlug: prevMeetingSlug } }, menu: { menu_tab_meeting: prev_menu_tab_meeting } } = prevProps || {};

    const targetDate = this.props?.schedules_details?.single_schedule?.schedule?.sd_activation_at;
    const timeZoneofMeeting = this.props?.schedules_details?.single_schedule?.schedule?.time_zone
    const targetTimezone = timeZoneofMeeting;

    const convertedTime = dayjs.utc(targetDate).tz(targetTimezone);
    const currentDatetime = dayjs(); // Current datetime in local timezone

    const differenceInDaysMilisec = convertedTime.diff(currentDatetime);

    const sameDayIsPresent = this.props?.schedules_details?.single_schedule?.schedule?.same_day

    if (sameDayIsPresent && differenceInDaysMilisec) {
      const delay = differenceInDaysMilisec

      if (delay > 0) {
        // Call API after delay
        this.timeoutId = setTimeout(() => {
          if (meetingSlug) {
            window.location.reload()
          }
        }, delay);
      }
    } 

    // Get new Delivery details and menu for new delivery
    if (prevMeetingSlug !== meetingSlug && meetingSlug) {
      getSingleSchedule(meetingSlug);
      getSingleDeliveryMenu(meetingSlug, 'lunch');
      // if Previously marketplace was on then move to menu detail page
      isEmpty(prevMeetingSlug) && this.setState({ scheduleMarketplaceDelivery: false });
    }
    const dynamicTitle = JSON.parse(localStorage.getItem('SEOTitle') || '{}')
    // Run Only when date and meeting slug is not present and menu tab meeting is recieved
    if (
      (!date || !dayjs(date ?? '', 'M-D-YYYY').isValid() || // Date is Not Present or is Invalid
        (date && isCompanyUser) || // Date is Present But is Company User
        (date && isCompanyAdmin && !enable_marketplace)) && // Date is Present But is Company Admin and Marketplace is Disabled
      !meetingSlug &&
      menu_tab_meeting !== prev_menu_tab_meeting
    ) {
      const { delivery_at, slug } = menu_tab_meeting || {};
      if (delivery_at && slug) {
        history.push(`/menu/${dayjs(delivery_at).format('M-D-YYYY')}/${slug}`);
        if (localStorage.getItem('order_id')) {
          this.ratingPopUp?.openModal();
        }
      } else if(self_signup && !slug) {
          const dateForMarketplace = dayjs().add(1, 'day').format('M-D-YYYY');
        
          history.push(`/menu/${dateForMarketplace}${!isEmpty(dynamicTitle) ? `?k=${dynamicTitle}` : ''}`)
          this.scheduleMarketplace()
      } else if(!enable_marketplace) {
          const currentHour = dayjs().hour();
          const dateForMarketplace = currentHour >= 14 ? dayjs().add(2, 'day') : dayjs().add(1, 'day');
          history.push(`/menu/${dateForMarketplace.format('M-D-YYYY')}`);
          this.meetingPopup?.openModal()
        } else {
          const currentHour = dayjs().hour();
          const dateForMarketplace = currentHour >= 14 ? dayjs().add(2, 'day') : dayjs().add(1, 'day');
          history.push(`/menu/${dateForMarketplace.format('M-D-YYYY')}${!isEmpty(dynamicTitle) ? `?k=${dynamicTitle}` : ''}`);
          
          if(!self_signup && !slug){
            if(isCompanyAdmin){
              window.location.reload()
            }
          }
          
          // causing problem for cart opening
          // window.location.reload(); // Force reload the page
        }
    }
  }

  componentWillUnmount() {
    window?.removeEventListener('resize', this.updateScreenWidth);
    document.removeEventListener('scroll', this.handleScroll);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

  }

  openCart = () => {
    this.setState({ showCart: true })
  }

  openModalEdit = (id: number, runningmenu: Delivery) => {
    this.orderPopup?.setState({
      companyData: JSON.parse(localStorage.getItem('company') || '{}'),
      menu_type: runningmenu.menu_type,
      model_type: 'edit',
      per_user_copay: runningmenu.per_user_copay
    });
    this.orderPopup?.openModalEdit(id);
  }

  onUpdatePage = () => {
    if (this.state.scheduleMarketplaceDelivery) {
      this.setState({ scheduleMarketplaceDelivery: false });
    }
    if (this.orderPopup?.state.field_required) {
      this.setState({ field_required: false })
      this.orderPopup.setState({ field_required: false })
    }
    this.props.match.params.meetingSlug && this.props.getSingleSchedule(this.props.match.params.meetingSlug)
  }

  onCancelMeeting = () => {
    this.props.history.push({ pathname: `/dashboard` });
  }

  onUpdateOrder = () => {
    // Updating View all Items Popup orders in case of order update.
    this.state?.showDeliveryOrdersSidebar && this.state.currentMeeting?.slug && this.props.getOrderDetails(this.state.currentMeeting.slug);
    // check if order successfully placed and at the time of order custom
    // fields were required than make it not required to avoid display it on popup
    if (this.orderPopup?.state.field_required) {
      this.setState({ field_required: false })
      this.orderPopup.setState({ field_required: false })
    }
    // Get Updated menu for current delivery after new order or Order Update
    this.props.match.params.meetingSlug && this.props.getSingleDeliveryMenu(this.props.match.params.meetingSlug, 'lunch');
  }

  openMeetingSpecific = () => {
    if (this.user && this.user.user_type === UserType.ADMIN && this.state.currentMeeting?.field_required) {
      this.meetingPopup?.openModal(this.state.currentMeeting.slug, 'approved', true);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: ApprovedMeetingMenuDetailProps) {
    const {
      schedules_details: { single_schedule, new_schedule_request_status },
      menu: { menu, delivery_recent_orders },
    } = this.props || {};
    const {
      schedules_details: {
        single_schedule: next_single_schedule,
        new_schedule_request_status: next_new_schedule_request_status,
      },
      menu: { menu: next_menu, delivery_recent_orders: next_user_recent_orders },
    } = nextProps || {};

    // Swap Single Schedule Details with new selected Schedule in Current Meeting
    if (single_schedule?.schedule !== next_single_schedule?.schedule && next_single_schedule?.schedule) {
      let newSelectedSchedule = next_single_schedule?.schedule;
      const { runningmenu_type, address_id, } = newSelectedSchedule;
      let showDeliveryOrdersSidebar = JSON.parse(localStorage.getItem('order_details') || 'false');
      if (showDeliveryOrdersSidebar) {
        // After Showing Order Detail Popup For the First Time Remove it from LocalStorage and From URL
        localStorage.removeItem('order_details');
        nextProps?.history?.replace({
          search: ''
        })
        this.setState({ currentMeeting: newSelectedSchedule, menu_type: runningmenu_type, address_id: address_id, showDeliveryOrdersSidebar: showDeliveryOrdersSidebar });
      } else {
        this.setState({ currentMeeting: newSelectedSchedule, menu_type: runningmenu_type, address_id: address_id });
      }
    }
    // Set Single Schedule Details in Current Meeting on Creating New Delivery
    if (next_new_schedule_request_status?.schedule?.id !== new_schedule_request_status?.schedule?.id) {
      const newCreatedSchedule = next_new_schedule_request_status?.schedule;
      if (newCreatedSchedule) {
        this.props.history.push(`/menu/${this.props.match.params.date}/${newCreatedSchedule?.slug}`);
      }
    }

    // Check if have to Open Popup Due to redirect from Email Order Reminder
    const { fooditem: emailOrderPopupId, section } = queryString.parse(window.location.search);
    if (((next_single_schedule?.schedule !== single_schedule?.schedule) || (next_menu !== menu) || (next_user_recent_orders !== delivery_recent_orders))
      && emailOrderPopupId && next_single_schedule && next_user_recent_orders && next_menu) {
      let popupFoodItem: undefined | FoodItem, orderDetail: RecentOrder | null = null;
      if (section === 'most_popular') {
        popupFoodItem = next_menu.most_popular.find(fooditem => fooditem.id === +emailOrderPopupId);
      } else if (section === 'top_picks') {
        popupFoodItem = next_menu.top_picks?.find(fooditem => fooditem.id === +emailOrderPopupId);
      } else if (section === 'recent_orders') {
        popupFoodItem = (next_user_recent_orders.recent_orders_fooditems.find((fooditem, index) => {
          if (fooditem.id === +emailOrderPopupId) {
            orderDetail = (next_user_recent_orders.recent_orders_data ?? [])[index];
            return true;
          }
          return false;
        }));
      }
      if (popupFoodItem) {
        // If this Popup is Open then donot open User Preferences Modal in Menu Area Component
        store.dispatch({ type: SET_POPUP_STATE_ON_MENU_DETAIL_PAGE, payload: true });
        const newSchedule = next_single_schedule?.schedule;
        const { id, address_id, per_user_copay, per_user_copay_amount, menu_type, admin_cutoff_at, cutoff_at, field_required } = newSchedule;
        this.orderPopup?.openModalWithItem(orderDetail, popupFoodItem, 0, address_id!, id!, per_user_copay_amount!);
        this.orderPopup?.setState({ field_required: this.state.field_required === false ? false : field_required, per_user_copay: per_user_copay, model_type: 'add', menu_type: menu_type, admin_cutoff_at: admin_cutoff_at, cutoff_at: cutoff_at, restaurants_name: popupFoodItem.restaurant_name });
      }
      nextProps?.history?.replace({
        search: ''
      })
    }

    // let trackingMeeting = this.state.currentMeeting;
    // if (trackingMeeting) {
    //   if (trackingMeeting.status !== 'pending') {
    //     var cable = ActionCable.createConsumer(`${process.env.REACT_APP_BASE_URL}cable?header=${localStorage.getItem('headers')}`)
    //     cable.subscriptions.create({ channel: 'DriverTrackChannel', meetingId: trackingMeeting.id }, {
    //       received: this.handleDriverTask
    //     });
    //   }
    // }
    if (next_menu && next_menu !== null && this.props.menu.menu !== nextProps?.menu?.menu) {
      this.setState({ companyData: JSON.parse(localStorage.getItem('company') || '{}') })
      this.orderPopup?.setState({ companyData: JSON.parse(localStorage.getItem('company') || '{}') })
    }
  }

  truncateString = (str: string, maxLen: number, separator: string = ' ') => {
    if (str?.length <= maxLen) return str;
    return str?.substr(0, str?.lastIndexOf(separator, maxLen)) + '...';
  }

  getPricesToShown = (foodPrice: number, skip_markup: boolean, per_meal_budget: number) => {
    return getPricesToShown(foodPrice, skip_markup, this.state.companyData ?? getloggedInUser().loggedInUserCompany, per_meal_budget);
  }

  cutOffPassed = (cutoff_at?: string) => {
    let cutOffPassed, companyAdmin = this.user && this.user.user_type === UserType.ADMIN;

    const sameDayActivation = this.state?.currentMeeting?.sd_activation_at
    const sameDayAdminCutoff = this.state?.currentMeeting?.sd_admin_cutoff_at
    const sameDayCutoff = this.state?.currentMeeting?.sd_cutoff_at
    const sameDayMeetingPresent = this.state?.currentMeeting?.same_day

    const currentDeliveryCutOff = (companyAdmin === true) ? this.state.currentMeeting?.admin_cutoff_at : this.state.currentMeeting?.cutoff_at;


    let normalSchedularPassedAndSameDaySchedulerPendingActivation = false
    if (dayjs(currentDeliveryCutOff).isBefore(new Date()) && sameDayMeetingPresent && dayjs(sameDayActivation).isAfter(new Date())) {
      normalSchedularPassedAndSameDaySchedulerPendingActivation = true
    }


    if (companyAdmin === true) {
      // old cutoff condition
      // cutOffPassed = cutoff_at ? dayjs(cutoff_at).isBefore(new Date()) : dayjs(this.state.currentMeeting?.admin_cutoff_at).isBefore(new Date());

      if (normalSchedularPassedAndSameDaySchedulerPendingActivation) {
        cutOffPassed = true
      } else if (sameDayMeetingPresent) {
        const activation_at_passed = dayjs(sameDayActivation).isBefore(new Date())
        const sd_admin_cutoff_check = dayjs(sameDayAdminCutoff).isBefore(new Date())
        cutOffPassed = activation_at_passed && (sd_admin_cutoff_check)
      } else {
        cutOffPassed = dayjs(this.state.currentMeeting?.admin_cutoff_at).isBefore(new Date());
      }

    } else if (normalSchedularPassedAndSameDaySchedulerPendingActivation) {
      cutOffPassed = true
    } else if (sameDayMeetingPresent) {
      const activation_at_passed = dayjs(sameDayActivation).isBefore(new Date())
      const sd_cutoff_check = dayjs(sameDayCutoff).isBefore(new Date())
      cutOffPassed = activation_at_passed && (sd_cutoff_check)
    } else {
      cutOffPassed = dayjs(this.state.currentMeeting?.cutoff_at).isBefore(new Date());
    }
    return cutOffPassed;
  }

  scheduleMarketplaceHiddenBtnHandler = () => {
    if (this.company.enable_marketplace) {
      this.scheduleMarketplace()
    } else if (!this.company.enable_marketplace && this.meetingPopup) {
      this.meetingPopup.openModal('', 'create')
    }
  }

  scheduleMarketplace = () => {
    this.setState({ scheduleMarketplaceDelivery: true, currentMeeting: null });
    if (this.marketplace !== null) {
      this.marketplace.setState({ viewRestaurantMenu: false });
    }
  }

  redirectToNewDelivery = (newDelivery: DeliveryOnSingleDate, deliveryDate: string | Date) => {
    if (!!newDelivery && !!deliveryDate && this.state?.currentMeeting?.slug !== newDelivery?.slug) {
      this.setState({
        scheduleMarketplaceDelivery: false,
      });
    }
  }

  trimRunningMenuName = (name: string) => {
    if (name?.split(' ’s Delivery').length > 1) {
      return name?.split(' ’s Delivery')[0] + '’s Delivery' + name?.split(' ’s Delivery')[1];
    } else {
      return name;
    }
  }

  // reloadIfCartChanged = () => {
  //   const currentUrl = window.location.pathname.split('/')
  //   window.location.reload()
  //   this.props.history.push(`/menu/${currentUrl[2]}/${currentUrl[3]}`);
  //   this.props.getCartAfterLogin( this.state.currentMeeting?.delivery_at )
  // }
  closeModal = () => {
    this.setState({ enterprizePopUp: false })
  };
  closeEnterpriseConfirmationPopUp = () => {
    this.setState({ enterprizePopUp: false, enterprizeConfirm: false })
  }
  openConfirmEnterprizePopUp = () => {
    this.setState({ enterprizePopUp: false, enterprizeConfirm: true, enterprizeEmailSent: true })
  }
  openEnterprizeModal = () => {
    this.setState({ enterprizePopUp: true })
  }
  scrollToTop = () => {
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }
  handleScroll = () => {
    let top: number = 0;
    let scrollChange: number = 0;
    const divElement = this.stickyDivRef.current;
    const stickyMarketPlaceFilter = this.restaurantHeaderCheck.current;
    if (divElement) {
      top  = divElement.getBoundingClientRect().top;
    }
    if(top){
      if(top < 147){
        this.setState({ divPositionCheck: true })
      }else if(top >= 147){
        this.setState({ divPositionCheck: false })
      }
      if(top < -52){
        this.setState({ yourOrderHeaderPaddingCheck: true })
      }else if(top >= -52){
        this.setState({ yourOrderHeaderPaddingCheck: false })
      }
    }
    if (stickyMarketPlaceFilter) {
      scrollChange  = +stickyMarketPlaceFilter.getBoundingClientRect().top;
    }
    if(scrollChange){
      if(scrollChange < 149){
        this.setState({ restaurantHeaderCheck: true })
      }else if (scrollChange >= 149){
        this.setState({ restaurantHeaderCheck: false })
      }
    }
    this.scrollHandlingofMarketPlace()
  }

  scrollHandlingofMarketPlace = () =>{
    if(this.props.scrollHandling){
      this.props.scrollHandling(this.state.divPositionCheck)
    }
  }
  render() {
    // Props Destructuring
    const {
      i18n,
      announcements = [],
      user: { delivery_dates = {} },
      history: { location: { pathname } },
      match: { params: { date = '', menu_type = null, address_id = null } = {} } = {},
      // menu_filter_redux,
      menu: {
        menu,
        menu_tab_meeting,
        loading: menuLoading,
        filter_purpose_loading,
        menu_tab_meeting_loading,
        isOpenPopupOnMenuDetailPage,
        delivery_recent_orders_loading,
        delivery_dynamic_sections_loading,
      },
      schedules_details: { single_schedule_loading },
    } = this.props;

    // State Destructuring
    const {
      screenWidth,
      currentMeeting,
      showDeliveryOrdersSidebar,
      scheduleMarketplaceDelivery,
      showCart
    } = this.state;

    // Getting responsive number of slides for Carousel on Menu Detail Page.
    const {
      noOfSlidesForRestaurants,
      noOfSlidesForUserLiking,
      noOfSlidesForFoodItems,
    } = getResponsiveNoOfSlides();

    let buffet_no_menu: number = 0;
    let document: Document = window.document;
    let MenuAreaSection: false | JSX.Element | JSX.Element[] | undefined, restaurants: DeliveryMenuRestaurant[];
    // To prevent click event on Dragging Slider Items in Carousel
    // let isDraggingCarouselItems = false;

    const showCarouselButtons = screenWidth > 768;
    const companyTimeZone: string = this.company && this.company.time_zone;
    const isCompanyAdmin = this.user && this.user.user_type === UserType.ADMIN;
    const gettingFutureMeeting = pathname === `/menu` && (menu_tab_meeting === undefined || menu_tab_meeting_loading);
    const isMarketPlacePreview = scheduleMarketplaceDelivery === true && this.marketplace?.state?.viewRestaurantMenu === true;
    // delivery_recent_orders_loading will be always FALSE when filter_purpose_loading is True (Refer to Action getSingleDeliveryMenu)
    const isRestaurantsAndDietariesLoading = (menuLoading && isEmpty(menu)) ||
      ((menuLoading || delivery_recent_orders_loading || delivery_dynamic_sections_loading)
        && !filter_purpose_loading);
    const isCloneOrdersPresent = !isEmpty(currentMeeting?.clone_orders) && isCompanyAdmin && !this.cutOffPassed?.() && ((currentMeeting?.total_quantity ?? 0) <= 0) && (currentMeeting?.clone_orders?.orders_count ?? 0) > 0;

    // Loading in case of getting Future Meeting
    const FutureMeetingLoading = <div className='h-100 w-100 d-flex justify-content-center align-items-center'><Spinner /></div>

    // Delivery Menu Part Start
    if (menuLoading === true || !menu || !currentMeeting || delivery_recent_orders_loading || delivery_dynamic_sections_loading) {
      MenuAreaSection = <MenuFoodItemsLoading totalSlides={Math.ceil(noOfSlidesForFoodItems)} visibleSlides={noOfSlidesForFoodItems} />;
    } else if (menu !== null && menuLoading === false) {
      restaurants = menu.restaurants;
      const menuType = currentMeeting?.menu_type;
      // only for user when type is buffet show no items
      MenuAreaSection = (isCompanyAdmin === false && menuType === 'buffet') ?
        !isEmpty(currentMeeting) && restaurants?.map((_restaurant, index) => (
          <div key={index}>
            {!isEmpty(_restaurant && _restaurant.sections) ? _restaurant.sections && _restaurant.sections.map((section, sectionIndex) => {
              const buffetMenuSection = section as unknown as BuffetMenuRstaurantSection;
              return <React.Fragment key={sectionIndex}>
                <div>
                  <h5 > {_restaurant.name} » {buffetMenuSection.section_name} </h5>
                  {buffetMenuSection?.fooditems?.map((_fooditem) => (
                    <div key={index} className='newContainer'>
                      <span className='d-none'>{buffet_no_menu = 1}</span>
                      <div className='block_'>
                        <div className='col_4' style={{ backgroundImage: `url(${_fooditem.image?.medium?.url})` }}></div>
                        <div className='col_8'>
                          <h3 className='text-capitalize float-left card-title mb-1'>{buffetMenuSection.section_type}: {_fooditem.name} </h3>
                          <p className='card-text d-block'>{_fooditem.description} {_fooditem.description ? <br /> : ''}
                          </p>
                          {_fooditem.options ? <span><b>Option: {_fooditem.options} </b></span> : ''}
                          <div className='options_'>
                            {!_fooditem.dietaries ? '' : _fooditem.dietaries.map(diet => (<span key={diet?.id}><b> {diet.name}</b> </span>))}
                            {_fooditem.ingredients && _fooditem.ingredients.map(ingredient => (<span key={ingredient?.id}> <b>{ingredient.name}</b> </span>))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            }) : ''
            }
          </div>
        )) :
        <MenuArea
          fromDashboard
          parentRef={this}
          menu={this.props.menu}
          currentMeeting={currentMeeting}
          cutOffPassed={this.cutOffPassed()}
          showCarouselButtons={showCarouselButtons}
          noOfSlidesForFoodItems={noOfSlidesForFoodItems}
          noOfSlidesForUserLiking={noOfSlidesForUserLiking}
          isOpenPopupOnMenuDetailPage={isOpenPopupOnMenuDetailPage}
        />
    }
    // Delivery Menu Part End

    return <React.Fragment>
      <>{(!menu_tab_meeting_loading && this.company.enable_marketplace !== true && (window.location.pathname?.split('/').length === 3 || menu_tab_meeting === undefined )) ?
        <>
          <Header
            scheduleMarketplaceDelivery={scheduleMarketplaceDelivery}
            toggleMenuDetailSideBar={() => this.setState({ isOpenSidebar: true })}
            showLeftSidebarToggler={isMarketPlacePreview || !scheduleMarketplaceDelivery}
            totalDeliveriesOnSingleDate={delivery_dates[dayjs(date, 'M-D-YYYY').format('YYYY-MM-DD')]?.length || 0}
            parentComponentRef={this}
            currentDelivery={currentMeeting as Delivery}
            showCart={(isOpen) => this.setState({ showCart: isOpen })}
            showDeliveryOrdersSidebar={(isOpen) => this.setState({ showDeliveryOrdersSidebar: isOpen })}
            isOpenSidebar={(isOpen) => this.setState({ isOpenSidebar: isOpen })}
            hideHeaderCart={this.state.hideHeaderCart}
          />
          <div id='show-notification' ></div>
          <div>
            <div className='user-list'>
              <div className='container m-auto pt-5 text-left top-spaces-5-5rem'>
                <div className='row pt-4 user-invite-div'>
                  <div className='col-12 m-auto px-3 px-md-0 d-flex align-items-center justify-content-between'>
                    <div>
                      <h2 className='d-inline' style={{ 'color': 'white', 'fontSize': '26px' }}>Schedule Your Delivery</h2>
                      <p className='lead' style={{ 'color': 'white', 'fontSize': '18px' }}>
                        {i18n && i18n?.t('care')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='marketPlacePage-parent'>
              <div className='container  scheduler-background p-0'>
                <div className='markePlacePage marketPlacepageForm'>
                  <MarketplaceScheduleDelivery
                    onRef={(ref: MeetingDetailsPopUpRef | null) => (this.meetingPopup = ref)}
                    selectedDate={dayjs(date, 'M-D-YYYY').toDate()}
                    selectedType={menu_type}
                    selectedAddress={address_id}
                    onUpdatePage={this.onUpdatePage.bind(this)}
                    marketPlace={true}
                    marketPlacePage={true}
                  />
                </div>
              </div>
            </div>
            <div className='marketPlacePage-parent' style={{ height: '30px' }}></div>
          </div>
        </> :
        <>
          {gettingFutureMeeting ? FutureMeetingLoading :
            <>{!isCompanyAdmin && (isEmpty(menu_tab_meeting) || isEmpty(currentMeeting)) ?
              <>
                <Header
                  scheduleMarketplaceDelivery={scheduleMarketplaceDelivery}
                  toggleMenuDetailSideBar={() => this.setState({ isOpenSidebar: true })}
                  showLeftSidebarToggler={isMarketPlacePreview || !scheduleMarketplaceDelivery}
                  totalDeliveriesOnSingleDate={delivery_dates[dayjs(date, 'M-D-YYYY').format('YYYY-MM-DD')]?.length || 0}
                  parentComponentRef={this}
                  currentDelivery={currentMeeting as Delivery}
                  showCart={(isOpen) => this.setState({ showCart: isOpen })}
                  showDeliveryOrdersSidebar={(isOpen) => this.setState({ showDeliveryOrdersSidebar: isOpen })}
                  isOpenSidebar={(isOpen) => this.setState({ isOpenSidebar: isOpen })}
                  hideHeaderCart={this.state.hideHeaderCart}
                />
                <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
                  <img src="/imgs/SVGs/smiley-face.svg" className='mt-5 pt-5' alt="no restaurant found" />
                  <h4 className='mt-32 fw-600 text-red-basic'>{'Sorry, no deliveries created'}</h4>
                  <p className='text-gray-primary fs-18 fw-normal text-center mb-0'>Please Reach out to your account admin to setup deliveries at your company.</p>
                  <p className='text-gray-primary fs-18 fw-normal text-center'>Have a question? Email us at <a href="mailto:support@chowmill.com">support@chowmill.com!</a></p>
                  <button onClick={() => { this.props.history.push({ pathname: `/profile` }); }} className="btn reset-btn-border-red fw-600 text-red-basic rounded-pill px-32 py-2">
                    Visit Profile
                  </button>
                </div>
              </>

              :
              <>
          <Header
            scheduleMarketplaceDelivery={scheduleMarketplaceDelivery}
            toggleMenuDetailSideBar={() => this.setState({ isOpenSidebar: true })}
            showLeftSidebarToggler={isMarketPlacePreview || !scheduleMarketplaceDelivery}
            totalDeliveriesOnSingleDate={delivery_dates[dayjs(date, 'M-D-YYYY').format('YYYY-MM-DD')]?.length || 0}
            parentComponentRef={this}
            currentDelivery={currentMeeting as Delivery}
            showCart={(isOpen) => this.setState({showCart: isOpen})}
            showDeliveryOrdersSidebar={(isOpen) => this.setState({ showDeliveryOrdersSidebar: isOpen})}
            isOpenSidebar={(isOpen) => this.setState({isOpenSidebar: isOpen})}
            hideHeaderCart={this.state.hideHeaderCart}
          />
          <div id='show-notification'></div>
          {/* Top Bar Section */}
          <div>
            {!scheduleMarketplaceDelivery &&
              <div className={`container-fluid px-0 ${this.state.yourOrderHeaderPaddingCheck && 'newdeliverypage-sticky hit-top yourOrderPageHeader-stickyPadding'}`}
              // style={{ paddingTop: screenWidth <= 992 ? this.state.divPositionCheck? '0px' : '0px' : 0 }}
              style={{ zIndex: '4' }}
              >
                {screenWidth > 992 && <DeliveryDetailsSection
                  currentDelivery={currentMeeting || {} as Delivery}
                  companyTimeZone={companyTimeZone}
                  isMarketPlacePreview={isMarketPlacePreview}
                  parentComponentRef={this}
                  redirectToNewDelivery={this.redirectToNewDelivery}
                  fromDashboard
                  hideCartOnHeader={this.hideCartOnHeader.bind(this)}
                  openCancelMeetingPopUp={this.openCancelMeetingModal.bind(this)}
                  yourOrderHeaderPaddingCheck={this.state.yourOrderHeaderPaddingCheck}
                />}
              </div>}
            {!scheduleMarketplaceDelivery &&
              <div className='container-fluid px-0' style={{ paddingTop: screenWidth <= 992 ? '73px' : '0' }}>
                {screenWidth > 992 ?
                <>
                    {/* temp header for Self-SignUp users */}
                    {/* <div className='bg-whaite px-0 py-4'>
                      <div className='d-flex align-items-center justify-content-between container'>
                        <div className=' d-flex'>
                          <div className='d-flex delivery-details-date'>
                            <span className="icon-wrapper-large">
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                              </svg>
                            </span>
                            <div className='pl-3'>
                              <p className='fs-18 fw-600 text-gray-600 m-0'>Tuesday, Sep 27 at 11:00 PM</p>
                              <span className='badge-outline-primary'>Cuttoff 4 hours</span>
                            </div>
                          </div>
                          
                          <div className='d-flex delivery-details-location'>
                            <span className="icon-wrapper-large ml-32 mr-3 position-relative">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="32" height="32" >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                              </svg>
                              <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position'>22</span>
                            </span>
                            <div className='ps-3'>
                              <p className='fs-18 fw-600 text-gray-600 m-0'>2345 , Harris Way, San Jose</p>
                              <p className='fs-16 m-0 text-gray-600 fw-normal'>6210 Lunch - 4th Floor</p>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex delivery-details-cart'>
                          <span className="icon-wrapper-large position-relative">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="33.003" viewBox="0 0 29.002 33.003">
                              <path id="bag_new" data-name="bag new" d="M7856,13129a6.508,6.508,0,0,1-6.5-6.5v-15a3.506,3.506,0,0,1,3.5-3.5h4.5v-1.5a6.5,6.5,0,0,1,13,0v1.5h4.5a3.507,3.507,0,0,1,3.5,3.5v15a6.508,6.508,0,0,1-6.5,6.5Zm-4.5-21.5v15a4.508,4.508,0,0,0,4.5,4.5h16a4.507,4.507,0,0,0,4.5-4.5v-15a1.505,1.505,0,0,0-1.5-1.5h-4.5v5a1,1,0,0,1-2,0v-5h-9v5a1,1,0,0,1-2,0v-5H7853A1.5,1.5,0,0,0,7851.5,13107.5Zm8-5v1.5h9v-1.5a4.5,4.5,0,0,0-9,0Z" transform="translate(-7849.5 -13095.998)" fill="#dc3644"/>
                            </svg>
                            <span className='badge badge-danger rounded-circle position-absolute icon-wrapper-badge-position'>0</span>
                          </span>
                          <div className='pl-3'>
                            <p className='fs-18 fw-normal text-gray-600 m-0'>$0</p>
                            <span  className="fs-18 fw-normal text-decoration-none">View Orders <span className='badge badge-primary rounded-circle ml-2'>1</span> </span>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    </>
                  :
                  <>
                    {/* Delivery Details Sidebar For Mobiles */}
                    <DeliveryDetailsForMobiles
                      currentDelivery={currentMeeting || {} as Delivery}
                      companyTimeZone={companyTimeZone}
                      isMarketPlacePreview={isMarketPlacePreview}
                      redirectToNewDelivery={this.redirectToNewDelivery}
                      parentComponentRef={this}
                      fromDashboard
                      onCloseSideBar={() => this.setState({ isOpenSidebar: false })}
                      isOpenSidebar={this.state?.isOpenSidebar}
                    />
                    {/* Mobile View Calendar */}
                    {this.localStorageCompany.enterprise_status !== 'converted' && <div className="enterprise-plan-banner d-md-none" onClick={() => {
                      if (!this.props?.converted_to_enterprize && this.localStorageCompany.enterprise_status !== 'requested') {
                        this.openEnterprizeModal();
                      }
                    }}>
                      <div className="container">
                      <span className={`d-flex align-items-center ${(this.props?.converted_to_enterprize || this.localStorageCompany.enterprise_status === 'requested') && 'opacity-3'}`}>
                        <img src="/imgs/crown.svg" alt="crown" className="mr-2 mobile-svg" />
                        <p className='enterprise-plan-heading m-0'>Upgrade to Enterprise Plan</p>
                      </span>
                      </div>
                    </div>}
                    {currentMeeting?.id &&
                      <div className='mobile-calendar px-2 px-sm-5 py-3'>
                        <DeliveryDatesCalendar
                          deliveryDate={currentMeeting?.delivery_at}
                          isMobileCalendar
                          onDateChangeHandler={this.redirectToNewDelivery}
                        />
                      </div>
                    }
                  </>}
                {/* Clone Orders Section */}
                <EmailVerification/>
                {/* Temporarily commented for SelfSignUp Post release */}
                {this.company.self_signup === false && isCloneOrdersPresent && !isRestaurantsAndDietariesLoading &&
                  <CloneOrders
                    currentDelivery={currentMeeting || {} as Delivery}
                    parentRef={this}
                    fromDashboard
                  />
                }
                {/* CuttOff Passed and Announcements Section */}
                {!single_schedule_loading &&
                  <AnnouncementsAndAlerts
                    announcements={announcements}
                    cuttofPassed={this.cutOffPassed()}
                  />}
                {/* Restaurants Logos and Dietries and Ingredients Section */}
                <div ref={this.stickyDivRef} className='restaurants-dietries-restrictions'>
                  {/* Restaurants Logos Section Sliders */}
                  <div className={`container-fluid px-0`}>
                    {isRestaurantsAndDietariesLoading ?
                      <MenuFiltersLoading restaurantSlidesCount={Math?.ceil(noOfSlidesForRestaurants)} /> :
                      <MenuFilters
                        fromDashboard
                        parentState={this.state}
                        menuLoading={menuLoading}
                        dietaries={menu?.dietaries}
                        ingredients={menu?.ingredients}
                        menuFilters={this.state.menuFiltersFromRedux ?? []}
                        restaurants={menu?.restaurants}
                        singleSchedulerMenu={menu}
                        showCarouselButtons={showCarouselButtons}
                        noOfSlidesForRestaurants={noOfSlidesForRestaurants}
                      />
                    }
                  </div>
                </div>
              </div>
            }
            <div className='container-fluid bg-white'>
              <div className='row'>
                <div id='mainMenueArea'
                  style={{ paddingTop: scheduleMarketplaceDelivery ? '78px' : 0 }}
                  className={!scheduleMarketplaceDelivery ? 'col-12 px-0 px-md-5' : 'col-12 px-0'}>
                  {scheduleMarketplaceDelivery ?
                    <Marketplace
                      dashboardRef={this}
                      onRef={(marketplaceRef: MarketplaceRef | null) => (this.marketplace = marketplaceRef)}
                      orderPopup={this.orderPopup}
                      setState={(p: any) => { this.setState(p) }}
                      selectedDate={dayjs(date, 'M-D-YYYY').toDate()}
                      onUpdatePage={this.onUpdatePage.bind(this)}
                      hideCartOnHeader={this.hideCartOnHeader.bind(this)}
                    />
                    :
                    <div className='m-auto px-0 px-md-2 mx-0 text-left main-menu-area'>
                      {MenuAreaSection}
                      {(isCompanyAdmin === false && currentMeeting?.menu_type === 'buffet' && buffet_no_menu === 0 && menuLoading === false)
                        ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <h3> No menu has been selected. Please reach out to your office admin for assistance. </h3></div> : ''}
                      {isEmpty(MenuAreaSection) ? '' : <Footer />}
                    </div>
                  }
                </div>
                {(isCompanyAdmin === true && this.company.enable_marketplace) ?
                  <>
                    <button
                      hidden={true}
                      id='scheduleClick'
                      className='btn btn-lg btn-danger btn-block mt-3'
                      disabled={isEmpty(date)}
                      onClick={this.scheduleMarketplaceHiddenBtnHandler}>
                      {i18n && i18n.t('Schedule Delivery')}</button>
                  </> : ''
                }
              </div>
                </div>
              </div>
            </>
            }
        </>
          }
        </>}

      </>
     

      <DeliveryOrdersSidebar
        selectedMeeting={currentMeeting}
        isOpenSidebar={showDeliveryOrdersSidebar}
        updateOrder={(id: number) => { currentMeeting && this.openModalEdit(id, currentMeeting) }}
        updatePageOnOrderDelete={() => currentMeeting?.slug && this.props.getSingleSchedule(currentMeeting?.slug)}
        closeSidebar={() => { this.setState({ showDeliveryOrdersSidebar: false }); document.title = 'Menu | Chowmill'; }}
      />

      <RatingPopUp
      ref={(ref: RatingPopUpRef | null) => (this.ratingPopUp = ref)}
      />

      <CancelMeeting
        orders={currentMeeting?.total_quantity}
        canceledDelivery={currentMeeting ?? null}
        onCancelMeeting={this.onCancelMeeting.bind(this)}
        passedCutOf={dayjs(currentMeeting?.admin_cutoff_at).isBefore(new Date())}
        // onRef={(ref: CancelMeetingPopUpRef | null) => (this.cancelMeeting = ref)}
        isModalOpen = {this.state.cancelDeliveryState}
        closeMeetingModal = {this.closeCancelMeetingModal.bind(this)}
      />

      <OrderPopup
        // onDashboard={true}
        setState={(s: ApprovedMeetingMenuDetailState) => this.setState(s)}
        meetingMealBudget={currentMeeting?.per_meal_budget}
        onUpdatePage={this.onUpdatePage.bind(this)}
        onUpdateOrder={this.onUpdateOrder.bind(this)}
        onClose={this.openMeetingSpecific.bind(this)}
        openCart={this.openCart}
        // company={this.company}
        companyObj={this.state.companyData}
        onRef={(popupRef: OrderPopupRef | null) => { this.orderPopup = popupRef }}
        remaining_budget={(Number(currentMeeting?.remaining_budget) < 0) ? `0` : currentMeeting?.remaining_budget}
        currentMeetingAddressId={currentMeeting?.address_id}
        currentMeetingDeliveryAt={currentMeeting?.delivery_at}
      />

      

      {/* <MarketplaceScheduleDelivery
        onRef={(ref: MeetingDetailsPopUpRef | null) => (this.meetingPopup = ref)}
        selectedDate={dayjs(date, 'M-D-YYYY').toDate()}
        selectedType={menu_type}
        selectedAddress={address_id}
        onUpdatePage={this.onUpdatePage.bind(this)}
        fromDashboard
      /> */}

      { window.location.pathname?.split('/').length > 3 ? <MeetingDetailsPopUp
        onRef={(ref: MeetingDetailsPopUpRef | null) => (this.meetingPopup = ref)}
        selectedDate={dayjs(date, 'M-D-YYYY').toDate()}
        selectedType={menu_type}
        selectedAddress={address_id}
        onUpdatePage={this.onUpdatePage.bind(this)}
        fromDashboard
      /> : <></>}

      <ShareMeetingPopUp
        ref={(ref: ShareMeetingPopUpRef | null) => { this.shareMenu = ref }}
        currentMeeting={currentMeeting ?? null}
      />

      <Cart
        isOpenSidebar={showCart}
        closeSidebar={() => { this.setState({ showCart: false }); document.title = 'Menu | Chowmill'; }}
        cartPage='cart'
        // fromPublicMarketPlace
        // reloadIfCartChanged={this.reloadIfCartChanged}
      />

        {this.state.enterprizePopUp && <EnterprizePlanViewerPopUp
        modalIsOpen={this.state.enterprizePopUp}
        closeModal={this.closeEnterpriseConfirmationPopUp}
        confirmAction={this.openConfirmEnterprizePopUp}
        />}
        {this.state.enterprizeConfirm && <EnterprizeConfirmationPopUp
          modalIsOpen={this.state.enterprizeConfirm}
          closeModal={this.closeEnterpriseConfirmationPopUp}
          confirmAction={() => { }}
        />
      }
      {(!menu_tab_meeting_loading && this.company.enable_marketplace !== true && (window.location.pathname?.split('/').length === 3 || menu_tab_meeting === undefined)) ? <></> : <div className='scrollToTop-button'>
        <button className='btn btn-danger rounded-circle' onClick={this.scrollToTop}>
          <span><i className="fas fa-chevron-up"></i></span>
        </button>
      </div>}
    </React.Fragment>
  }
}

const mapStateToProps = (state: ReduxRootState) => ({
  menu: state?.menu,
  // menu_filter_redux: state?.menu?.menu_filters,
  order: state?.order,
  schedules_details: state?.schedules_details,
  i18n: state?.i18n?.i18n,
  schedule_update_request: state?.schedules_details?.update_schedule_status,
  cuisines: state?.cuisine_list?.cuisines,
  user: state?.users,
  announcements: state?.users?.announcements,
  converted_to_enterprize: state?.company_data_reducer?.converted_to_enterprize,
  // seledtedAddressId: state?.addresses?.public_addresses
});

export type ApprovedMeetingMenuDetailRef = React.ElementRef<typeof ApprovedMeetingMenuDetail>;

export default connect(mapStateToProps, { getShowMenuTab, getAnnouncements, getCartAfterLogin, getDeliveryDates, getSingleDeliveryMenu, getOrderDetails,getDynamicTitles, getSingleSchedule, getCompanyForOrderPurpose })(ApprovedMeetingMenuDetail);
