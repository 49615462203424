import axios from 'axios';
import { GET_MENU, GET_MENU_OF_RESTAURANT, GET_MEETINGS_FOR_MAIN_DASHBOARD, GET_SCHEDULE, MENU_REDUCER_LOADING, GET_MEETINGS_LOADING, SET_MENU_TAB_MEETING, GET_DELIVERY_RECENT_ORDERS, GET_DELIVERY_RECENT_ORDERS_LOADING, GET_MENU_TAB_MEETING_LOADING, GET_DELIVERY_DYNAMIC_SECTIONS, GET_DELIVERY_DYNAMIC_SECTIONS_LOADING, CART_ADDRESS_IDS, MARKETPLACE_PAGE, GET_MENU_FILTERS } from './types';
import { getError } from './errorActions';
import { getFutureDelivery } from './userActions';
import { AdminApprovedDelivery } from '../Interfaces/PayloadsAndResponses/Menu';
import store, { AppThunkAction } from '../store';
import { getloggedInUser } from '../Utilities/getloggedInUser';
import isEmpty from '../Utilities/is_empty';
const queryString = require('query-string');

type DeliveryType = 'your_deliveries' | 'delivery_requests' | 'your_locations' | 'other_locations'
const DeliveriesTypes = {
  your_deliveries: 'admin_approved_deliveries',
  delivery_requests: 'admin_delivery_requests',
  your_locations: 'user_location_deliveries',
  other_locations: 'user_other_locations_deliveries',
}

// GET Menu by Date
export const getSingleDeliveryMenu = (deliverySlug: string | undefined, menu_type = '', restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], token?: string, filterPurpose = false): AppThunkAction => dispatch => {
  if (menu_type) {
    dispatch(setMenuReducerLoading(filterPurpose));
    if (!filterPurpose) {
      // API to get Latest ten orders for current meeting;
      deliverySlug && dispatch(getSingleDeliveryRecentOrders(deliverySlug, token, restaurantIDs));
      // API to get Dynamic Sections for current meeting based on Delivery Tags;
      (deliverySlug || token) && dispatch(getSingleDeliveryDynamicSections(deliverySlug ?? '', token));
    }
    axios
      .get(token ? `/schedules/${deliverySlug}/menus?token=${token}` : `/schedules/${deliverySlug}/menus`,
        {
          params: { 'r[]': restaurantIDs, 'd[]': dietaryIDs, 'i[]': ingredientIDs },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        dispatch({
          type: MARKETPLACE_PAGE,
          payload: false
        });
        dispatch({
          type: GET_MENU,
          payload: res.data
        });

        if (isEmpty(restaurantIDs) && isEmpty(dietaryIDs) && isEmpty(ingredientIDs)) {
          const menuFilters = {
            menu_filters: res.data.filters,
            restaurants: res.data.restaurants
          }
          dispatch({
            type: GET_MENU_FILTERS,
            payload: menuFilters
          });
        }

        const { loggedInUserCompany } = getloggedInUser();
        if (loggedInUserCompany?.self_signup === true) {
          const menuRestaurantsIdArray = res?.data?.restaurants?.map((restaurant: any) => {
            return restaurant?.address_id
          })
          store.dispatch({
            type: CART_ADDRESS_IDS,
            payload: menuRestaurantsIdArray
          });
        }
      }
      )
      .catch(err =>
        dispatch(getError(err))
      );
  }
};

export const getSingleDeliveryRecentOrders = (deliverySlug: string, token = '', restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[]): AppThunkAction => dispatch => {
  if (deliverySlug) {
    dispatch(setSingleDeliveryRecentOrdersLoading());
    axios
      .get(`/schedules/${token ? undefined : deliverySlug}/recent${token ? `?token=${token}` : ''}`,
        {
          params: { 'r[]': restaurantIDs, 'd[]': dietaryIDs, 'i[]': ingredientIDs },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => dispatch({
        type: GET_DELIVERY_RECENT_ORDERS,
        payload: res.data
      }))
      .catch(err =>
        dispatch(getError(err))
      );
  }
};

export const getSingleDeliveryDynamicSections = (deliverySlug: string, token = '', restaurantIDs?: number[]): AppThunkAction => dispatch => {
  if (deliverySlug || token) {
    dispatch(setSingleDeliveryDynamicSectionsLoading());
    axios
      .get(`/schedules/${token ? undefined : deliverySlug}/menus/dynamic_sections${token ? `?token=${token}` : ''}`,
        {
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        dispatch({
          type: GET_DELIVERY_DYNAMIC_SECTIONS,
          payload: res.data
        })
      })
      .catch(err =>
        dispatch(getError(err))
      );
  }
};
// for combos menu
export const getMenusOfRestaurantCombos = (address_ids: number[], mealTypeForMenuCheckAPI = 'individual', filterApplied?: boolean, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], filterPurpose = false): AppThunkAction => dispatch => {
  dispatch(setMenuReducerLoading(filterPurpose));
   let getComboMenuRestaurants = `/addresses/menus?menu_type=1`
  axios
  .get(getComboMenuRestaurants,
      {
        params: { 'r[]': restaurantIDs, 'd[]': dietaryIDs, 'i[]': ingredientIDs, 'address_ids[]': address_ids, 'meal_type': mealTypeForMenuCheckAPI},
        paramsSerializer: function (params) {
          return queryString.stringify(params, { indices: false })
        },
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_MENU_OF_RESTAURANT,
        payload: res.data,
        filterApplied: filterApplied
      })
      const comboAllAllowedRestaurantsIdArray = res?.data?.restaurants?.map((restaurant: any) => {
        return restaurant?.address_id
      })
      const allowedRestaurantsIdArray = comboAllAllowedRestaurantsIdArray?.filter((id: number) => id)

      store.dispatch({
        type: CART_ADDRESS_IDS,
        payload: allowedRestaurantsIdArray
      });
    }
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getMenusOfRestaurant = (addressId: number, menuType: number, mealTypeForMenuCheckAPI = 'individual', filterApplied?: boolean, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], filterPurpose = false, ): AppThunkAction => dispatch => {
  const address_ids = [addressId]
  dispatch(setMenuReducerLoading(filterPurpose));
  let menuType
  if (mealTypeForMenuCheckAPI === 'individual') {
    menuType = 1
  }else if (mealTypeForMenuCheckAPI === 'buffet') {
    menuType = 4
  }
  let getMenuRestaurants = `/addresses/menus?menu_type=${menuType}`
  axios
    .get(getMenuRestaurants,
      {
        params: { 'r[]': restaurantIDs, 'd[]': dietaryIDs, 'i[]': ingredientIDs, 'address_ids[]': address_ids, 'meal_type': mealTypeForMenuCheckAPI },
        paramsSerializer: function (params) {
          return queryString.stringify(params, { indices: false })
        },
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_MENU_OF_RESTAURANT,
        payload: res.data,
        filterApplied: filterApplied
      })
    }
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

export const getMenusOfRestaurantReValidate = (addressId: number, menuType: number, mealTypeForMenuCheckAPI = 'individual',placeId: number, delivery_at: string, backToMarketplace: () => void, filterApplied?: boolean, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], filterPurpose = false, ): AppThunkAction => dispatch => {
  const address_ids = [addressId]
  dispatch(setMenuReducerLoading(filterPurpose));
  let menuType
  if (mealTypeForMenuCheckAPI === 'individual') {
    menuType = 1
  }else if (mealTypeForMenuCheckAPI === 'buffet') {
    menuType = 4
  }
  let getMenuRestaurants = `/addresses/menus?menu_type=${menuType}`
  axios
    .get(getMenuRestaurants,
      {
        params: { 'r[]': restaurantIDs, 'd[]': dietaryIDs, 'i[]': ingredientIDs, 'address_ids[]': address_ids, 'meal_type': mealTypeForMenuCheckAPI, 'validate_request': 1, 'address_id': placeId, 'delivery_at': delivery_at },
        paramsSerializer: function (params) {
          return queryString.stringify(params, { indices: false })
        },
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_MENU_OF_RESTAURANT,
        payload: res.data,
        filterApplied: filterApplied
      })
    }
    )
    .catch(err => {
      dispatch(getError(err))
      backToMarketplace?.()
    }
    );
};

export const getPublicMenusOfRestaurantReValidate = (addressId: number, menuType: number, mealTypeForMenuCheckAPI = 'individual', lat: number | null, long: number | null, time_zone: string, delivery_at: string, backToMarketplace: () => void, filterApplied?: boolean, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], filterPurpose = false,): AppThunkAction => dispatch => {
  const address_ids = [addressId]
  dispatch(setMenuReducerLoading(filterPurpose));
  let menuType
  if (!isEmpty(time_zone)) {
    if (mealTypeForMenuCheckAPI === 'individual') {
      menuType = 1
    } else if (mealTypeForMenuCheckAPI === 'buffet') {
      menuType = 4
    }
    let getMenuRestaurants = `/addresses/menus?menu_type=${menuType}`
    axios
      .get(getMenuRestaurants,
        {
          params: { 'r[]': restaurantIDs, 'd[]': dietaryIDs, 'i[]': ingredientIDs, 'address_ids[]': address_ids, 'meal_type': mealTypeForMenuCheckAPI, 'validate_request': 1, 'lat': lat, 'long': long, 'time_zone': time_zone, 'delivery_at': delivery_at },
          paramsSerializer: function (params) {
            return queryString.stringify(params, { indices: false })
          },
          headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
      .then(res => {
        dispatch({
          type: GET_MENU_OF_RESTAURANT,
          payload: res.data,
          filterApplied: filterApplied
        })
      }
      )
      .catch(err => {
        dispatch(getError(err))
        backToMarketplace?.()
      }
      );
  }
};

export const addToFavourites = (foodItemId: number): AppThunkAction => dispatch => {
  axios
    .post(`/fooditems/${foodItemId}/favorites`, undefined,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .catch(err =>
      dispatch(getError(err))
    );
}

export const removeFromFavourites = (foodItemId: number): AppThunkAction => dispatch => {
  axios
    .delete(`/fooditems/${foodItemId}/favorites`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .catch(err =>
      dispatch(getError(err))
    );
}


export const getMeetingsByRange = (deliveriesType: DeliveryType, from: string, to: string, queryParams: string, updatedMeeting?: AdminApprovedDelivery): AppThunkAction => dispatch => {
  const { page } = queryString?.parse(queryParams);
  const deliveryTypeForReducer = DeliveriesTypes[deliveriesType];
  dispatch({ type: GET_MEETINGS_LOADING, payload: deliveryTypeForReducer + '_loading' })
  axios
    .get(`/dashboard/meetings/${deliveriesType}?from=${from}&to=${to}${queryParams}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      dispatch({
        type: GET_MEETINGS_FOR_MAIN_DASHBOARD,
        payload: res.data,
        deliveriesType: deliveryTypeForReducer,
        updatedMeeting,
        page
      });
    }
    )
    .catch(err =>
      dispatch(getError(err))
    );
};

// GET Schedule
export const getSchedule = (token = ''): AppThunkAction => dispatch => {
  axios
    .get(token ? `/menus/schedules?token=${token}` : '/menus/schedules',
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res =>
      dispatch({
        type: GET_SCHEDULE,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(getError(err));

    });
};

export const emptyMenus = (type: string): AppThunkAction => dispatch => {
  dispatch({
    type,
    payload: null
  });
}

export const getShowMenuTab = (): AppThunkAction => async dispatch => {
  dispatch(setShowMenuTabLoading());
  const { data: { meeting = null } = {} } = await getFutureDelivery(true) || {};
  dispatch({
    type: SET_MENU_TAB_MEETING,
    payload: meeting
  });
}

// Set Loading For Getting Menu Tab Delivery
export const setShowMenuTabLoading = () => {
  return {
    type: GET_MENU_TAB_MEETING_LOADING
  };
}

// Status Loading
export const setMenuReducerLoading = (filter_purpose_loading = false) => {
  return {
    type: MENU_REDUCER_LOADING,
    payload: { filter_purpose_loading }
  };
}

// Set Loading for recent order by meeting menu.
export const setSingleDeliveryRecentOrdersLoading = () => {
  return {
    type: GET_DELIVERY_RECENT_ORDERS_LOADING
  };
}

// Set Loading for Dynamic Sections order by meeting menu.
export const setSingleDeliveryDynamicSectionsLoading = () => {
  return {
    type: GET_DELIVERY_DYNAMIC_SECTIONS_LOADING
  };
}
