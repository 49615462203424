export const SET_INVITE_VENDOR_BUTTON_LOADING = 'SET_INVITE_VENDOR_BUTTON_LOADING';
export const REMOVE_INVITE_VENDOR_BUTTON_LOADING = 'REMOVE_INVITE_VENDOR_BUTTON_LOADING';
export const SET_ALL_BUTTONS_LOADING_STATE = 'SET_ALL_BUTTONS_LOADING_STATE';
export const LOADING = 'LOADING';
export const MENU_REDUCER_LOADING = 'MENU_REDUCER_LOADING';
export const COMPANY_REDUCER_LOADING = 'COMPANY_REDUCER_LOADING';
export const LOADING_ORDER = 'LOADING_ORDER';
export const LOADING_ORDER_CANCEL = 'LOADING_ORDER_CANCEL';
export const STOP_LOADING_ORDER_CANCEL = 'STOP_LOADING_ORDER_CANCEL';
export const SET_INVOICE_DOWNLOADING_LOADING = 'SET_INVOICE_DOWNLOADING_LOADING';
export const STOP_INVOICE_DOWNLOADING_LOADING = 'STOP_INVOICE_DOWNLOADING_LOADING';
export const LOADING_INVITED_USERS = 'LOADING_INVITED_USERS';
export const INVITE_USER_FOR_MEETING_BY_TOKEN = 'INVITE_USER_FOR_MEETING_BY_TOKEN';
export const GET_ERRORS = 'GET_ERRORS';
export const GET_CUISINE = 'GET_CUISINE';
export const GET_USERS_LIST_FOR_CONFIRMATION = 'GET_USERS_LIST_FOR_CONFIRMATION';
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_BILLING_DATA = 'SET_BILLING_DATA';
export const GET_BILLING_DATA = 'GET_BILLING_DATA';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const GET_COMPANY_TAGS = 'GET_COMPANY_TAGS';
export const GET_COMPANY_TIMEZONES = 'GET_COMPANY_TIMEZONES';
export const GET_COMPANY_ADDRESSES_FOR_USER = 'GET_COMPANY_ADDRESSES_FOR_USER';
export const CONVERTED_TO_ENTERPRIZE = 'CONVERTED_TO_ENTERPRIZE';
export const GET_USER_TYPES = 'GET_USER_TYPES';
export const GET_FILTER_COUNT_DELETED = 'GET_FILTER_COUNT_DELETED';
export const GET_FILTER_COUNT_INVITED = 'GET_FILTER_COUNT_INVITED';
export const GET_FILTER_COUNT_ADMIN = 'GET_FILTER_COUNT_ADMIN';
export const GET_FILTER_COUNT_MANAGER = 'GET_FILTER_COUNT_MANAGER';
export const GET_FILTER_COUNT_UNSUBSIZIZED = 'GET_FILTER_COUNT_UNSUBSIZIZED';
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const DELETE_USER = 'DELETE_USER';
export const REACTIVATE = 'REACTIVATE';
export const GET_MENU = 'GET_MENU';
export const GET_MENU_FILTERS= 'GET_MENU_FILTERS'
export const SET_MENU_TAB_MEETING = 'SET_MENU_TAB_MEETING';
export const GET_MENU_TAB_MEETING_LOADING = 'GET_MENU_TAB_MEETING_LOADING';
export const SET_POPUP_STATE_ON_MENU_DETAIL_PAGE = 'SET_POPUP_STATE_ON_MENU_DETAIL_PAGE';
export const GET_MENU_OF_RESTAURANT = 'GET_MENU_OF_RESTAURANT';
export const GET_MEETINGS_LOADING = 'GET_MEETINGS_LOADING'
export const GET_MEETINGS_FOR_MAIN_DASHBOARD = 'GET_MEETINGS_FOR_MAIN_DASHBOARD';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const CREATE_NEW_ORDER = 'CREATE_NEW_ORDER';
export const UPDATE_SINGLE_ORDER = 'UPDATE_SINGLE_ORDER'
export const GET_COMPANY_ALL_DELIVERIES_FOR_ORDERS = 'GET_COMPANY_ALL_DELIVERIES_FOR_ORDERS';
export const GET_DELIVERY_ORDERS_DETAILS = 'GET_DELIVERY_ORDERS_DETAILS';
export const GET_SELFSIGNUP_DELIVERY_ORDERS_DETAILS = 'GET_SELFSIGNUP_DELIVERY_ORDERS_DETAILS';
export const EMPTY_DELIVERY_ORDERS_DETAILS = 'EMPTY_DELIVERY_ORDERS_DETAILS';
export const GET_SINGLE_ORDER = 'GET_SINGLE_ORDER';
export const GET_DELIVERY_RECENT_ORDERS = 'GET_DELIVERY_RECENT_ORDERS';
export const GET_DELIVERY_RECENT_ORDERS_LOADING = 'GET_DELIVERY_RECENT_ORDERS_LOADING';
export const GET_DELIVERY_DYNAMIC_SECTIONS = 'GET_DELIVERY_DYNAMIC_SECTIONS';
export const GET_DELIVERY_DYNAMIC_SECTIONS_LOADING = 'GET_DELIVERY_DYNAMIC_SECTIONS_LOADING';
export const SEND_ORDER_DETAILS_EMAIL = 'SEND_ORDER_DETAILS_EMAIL';
export const GET_COMPANY_ADDRESSES = 'GET_COMPANY_ADDRESSES';
export const GET_PUBLIC_ADDRESSES = 'GET_PUBLIC_ADDRESSES';
export const MAKE_NEW_SCHEDULE_REQUEST = 'MAKE_NEW_SCHEDULE_REQUEST';
export const EMPTY_NEW_SCHEDULE_REQUEST_DATA = 'EMPTY_NEW_SCHEDULE_REQUEST_DATA';
export const GET_SINGLE_SCHEDULE = 'GET_SINGLE_SCHEDULE';
export const GET_SINGLE_SCHEDULE_LOADING = 'GET_SINGLE_SCHEDULE_LOADING';
export const CANCEL_DELIVERY = 'CANCEL_DELIVERY';
export const SET_DELIVERY_DATES = 'SET_DELIVERY_DATES';
export const REMOVE_ALL_DELIVERY_DATES = 'REMOVE_ALL_DELIVERY_DATES';
export const UPDATE_SCHEDULE_REQUEST = 'UPDATE_SCHEDULE_REQUEST';
export const SCHEDULE_MEETING = 'SCHEDULE_MEETING';
export const GET_INVITED_USERS_FOR_MEETING = 'GET_INVITED_USERS_FOR_MEETING';
export const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS';
export const GET_SELECTED_ADDRESS_ID = 'GET_SELECTED_ADDRESS_ID';
// comming from stagingi
export const SEND_FORGOT_PASSWORD_EMAIL = 'SEND_FORGOT_PASSWORD_EMAIL';
export const RESET_STATUS = 'RESET_STATUS';
export const RESEND_INVITE = 'RESEND_INVITE';
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const GET_COMPANY_FOR_ORDER_PURPOSE = 'GET_COMPANY_FOR_ORDER_PURPOSE';
export const UPDATE_COMPANY_FOR_ORDER_PURPOSE = 'UPDATE_COMPANY_FOR_ORDER_PURPOSE';
export const GENERATE_SHARE_MEETING_TOKEN = 'GENERATE_SHARE_MEETING_TOKEN';
export const EMPTY_ORDER_RATING_DATA = 'EMPTY_ORDER_RATING_DATA';
export const GET_FOODITEM_FOR_RATING = 'GET_FOODITEM_FOR_RATING';
export const GET_RESTAURANT_FOR_RATING = 'GET_RESTAURANT_FOR_RATING';
export const STOP_LOADING_ORDER = 'STOP_LOADING_ORDER';
export const GET_LOADING_OFF = 'GET_LOADING_OFF';
export const TRANSLATE_I18N = 'TRANSLATE_I18N';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_MARKETPLACE_SUGGESTIONS = 'GET_MARKETPLACE_SUGGESTIONS';
export const GET_MARKETPLACE_RESTAURANTS = 'GET_MARKETPLACE_RESTAURANTS';
export const GET_MARKETPLACE_COMBOS = 'GET_MARKETPLACE_COMBOS';
export const GET_MARKETPLACE_FAVORITES = 'GET_MARKETPLACE_FAVORITES';
export const CLONE_ORDERS = 'CLONE_ORDERS';
export const LOADING_CHILD_COMPANIES = 'LOADING_CHILD_COMPANIES';
export const GET_STATISTICS = 'GET_STATISTICS';
//Button Loading
export const START_BUTTON_LOADING = 'START_BUTTON_LOADING';
export const STOP_BUTTON_LOADING = 'STOP_BUTTON_LOADING';
//Account Page
export const GET_CHILD_COMPANIES_LIST = 'GET_CHILD_COMPANIES_LIST';
export const GET_COMPANY_ADMINS_LIST = 'GET_COMPANY_ADMINS_LIST';
export const GET_COMPANY_LOCATIONS = 'GET_COMPANY_LOCATIONS';
export const ADD_NEW_DEPARTMENT = 'ADD_NEW_DEPARTMENT';
export const ADD_COMPANY_ADMIN = 'ADD_COMPANY_ADMIN';
export const ADD_COMPANY_LOCATION = 'ADD_COMPANY_LOCATION';
export const UPDATE_MEAL_BUDGET = 'UPDATE_MEAL_BUDGET';
export const UPDATE_COMPANY_ADMIN = 'UPDATE_COMPANY_ADMIN';
export const DELETE_COMPANY_ADMIN = 'DELETE_COMPANY_ADMIN';
export const DELETE_COMPANY_LOCATION = 'DELETE_COMPANY_LOCATION';
export const SET_CONFIRM_ORDER_POPUP_STATE = 'SET_CONFIRM_ORDER_POPUP_STATE';
//Cart
export const CART_ITEMS = 'CART_ITEMS';
export const CART_ITEMS_UPDATE_ALL = 'CART_ITEMS_UPDATE_ALL';
export const GET_DELIVERY_FEE = 'GET_DELIVERY_FEE';
export const CART_DETAILS = 'CART_DETAILS';
export const CART_SUMMARY = 'CART_SUMMARY';
export const CART_ADDRESS_IDS = 'CART_ADDRESS_IDS';
export const CART_SUMMARY_TIP = 'CART_SUMMARY_TIP';
export const CART_SUMMARY_TIP_PERCENTAGE = 'CART_SUMMARY_TIP_PERCENTAGE';
export const EMPTY_CART = 'EMPTY_CART'; 
export const CART_AT_SERVER = 'CART_AT_SERVER';
export const CART_DETAILS_ITEM_COUNT = 'CART_DETAILS_ITEM_COUNT';
export const SAVE_ORDER_DATA_FOR_CONFIRMATION = 'SAVE_ORDER_DATA_FOR_CONFIRMATION';
export const SAVE_ITEM_NAME_FOR_NOTIFICATION = 'SAVE_ITEM_NAME_FOR_NOTIFICATION';
export const CART_ADDRESS_IDS_LOCAL = 'CART_ADDRESS_IDS_LOCAL';
export const SAVE_CART_ORDER_DATA_FOR_CONFIRMATION = 'SAVE_CART_ORDER_DATA_FOR_CONFIRMATION';
export const SAVE_CART_ORDER_PAYLOAD = 'SAVE_CART_ORDER_PAYLOAD';
export const CART_DISPLAY_CHECK = 'CART_DISPLAY_CHECK';
export const CONTINUE_ORDERING_FOR_SAME_CART = 'CONTINUE_ORDERING_FOR_SAME_CART';
//Address
export const GET_ADDRESS_DATA = 'GET_ADDRESS_DATA';
export const MARKETPLACE_PAGE = 'MARKETPLACE_PAGE';
export const MARKETPLACE_SECTION_SCROLL = 'MARKETPLACE_SECTION_SCROLL';
export const MARKETPLACE_SECTION_SCROLL_PERMISSION = 'MARKETPLACE_SECTION_SCROLL_PERMISSION';
export const MARKETPLACE_MOST_POPULAR_RESTAURANTS = 'MARKETPLACE_MOST_POPULAR_RESTAURANTS';
export const MARKETPLACE_POPULAR_DISHES = 'MARKETPLACE_POPULAR_DISHES';
export const MARKETPLACE_NEW_RESTAURANTS_NEAR_YOU = 'MARKETPLACE_NEW_RESTAURANTS_NEAR_YOU'
export const MARKETPLACE_FILTERS = 'MARKETPLACE_FILTERS';
export const GET_DYNAMICTITLES = 'GET_DYNAMICTITLES';
// redux state reseting type
export const RESET_STATE = 'RESET_STATE';
//Loading
export const IS_LOADING = 'IS_LOADING'